import React from "react"
import UnsavedChangesIcon from "../Alert/UnsavedChangesIcon"
import StyledTreeItem from "./StyledTreeItem"
import history from "../../utils/history"
import CourseTreePage from "./CourseTreePage"
import { useChapterUnsavedState } from "../../contexts/courseTree/ChapterUnsavedStateContext"


const handleChapterTreeClick = (event, value) => {
  event.preventDefault()
  const href = `/course/${value[0]}/chapter/${value[1]}`
  history.push(href)
}

const CourseTreeChapter = ( { treeData, courseid }) => {
  const chapterId = "chapter/" + treeData.number
  const state = useChapterUnsavedState()
  const sortedPages = treeData.pages.slice().filter(c => c.pageType !== "CHAPTERSUMMARY").sort(function (a, b) { return (a.number - b.number) })

  return (
    <StyledTreeItem
      nodeId={chapterId}
      labelInfo={state[treeData.id]?.unsaved && <UnsavedChangesIcon text={"Unsaved changes to this chapter"} /> }
      labelText={treeData.number + ": " + treeData.name}
      onLabelClick={(e) => handleChapterTreeClick(e, [courseid, treeData.number])}>
      {Array.isArray(sortedPages) ? sortedPages.map((node) => <CourseTreePage key={"page"+node.id} page={node} chapternumber={treeData.number} chapterid={treeData.id} courseid={courseid} />) : null}
    </StyledTreeItem>
  )

}

export default React.memo(CourseTreeChapter)