import React, { useEffect } from "react"
import { useMutation } from "@apollo/client"
import { makeStyles } from "@material-ui/core/styles"
import DialogTitle from "@material-ui/core/DialogTitle"
import Dialog from "@material-ui/core/Dialog"
import { Tooltip, Grid, Button, DialogContent } from "@material-ui/core"
import { green, red } from "@material-ui/core/colors"
import HistoryIcon from "@material-ui/icons/History"
import DiffDialog from "./DiffDialog"
import { REVERT_TO_HISTORY_ELEMENT } from "./../../gql/revert"
import { useSnackbar } from "notistack"

const useStyles = makeStyles(() => ({
  content: {
    padding: "20px",
    "& ins": {
      color: green[600],
    },
    "& del": {
      color: red[600],
    },
  },
  icon: {
    marginLeft: "10px",
    marginTop: "15px",
  },
  grid: {
    minWidth: "200px",
    padding: "20px",
  },
}))


export default function RevertDialog({ data, type, align, refetch }) {
  const classes = useStyles()
  const [revertHistory, { loading: revertLoading, data: revertData }] = useMutation(REVERT_TO_HISTORY_ELEMENT)
  const [open, setOpen] = React.useState(false)
  const { enqueueSnackbar } = useSnackbar()
  let changes = []
  let changeFields =[]

  switch(type) {
    case "multiple":
      changes = data.changes.data.map( (change) => {
        return { id: change.id, fieldName: change.FieldName, currentValue: change.ValueAfter, changeToValue: change.ValueBefore }
      })
      changeFields = data.changes.fields.split(",")
      break
    default:
      changes = [{ id: data.id, fieldName: data.FieldName, currentValue: data.ValueAfter, changeToValue: data.ValueBefore }]
      changeFields = [data.FieldName]
  }

  useEffect( () => {
    if(revertData !== undefined) {
      if(revertData.revertToHistoryElement) {
        enqueueSnackbar("Changes reverted", { variant: "success" })
        refetch()
      } else {
        enqueueSnackbar("Reverting changes failed", { variant: "error" })
      }
    }

  }, [revertData, enqueueSnackbar, refetch])

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const revert = () => {
    revertHistory({ variables: { id: data.id, fieldNames: changeFields } })
  }

  return (
    <>
      <Tooltip title="Revert changes">
        <HistoryIcon className={!align && classes.icon} onClick={handleClickOpen} />
      </Tooltip>
      <Dialog onClose={handleClose} className={classes.dialog} aria-labelledby="revert changes" open={open} maxWidth={"lg"} >
        <DialogTitle id="visualized-changes-title">After revertion</DialogTitle>
        <DialogContent>
          <Grid className={classes.grid} container spacing={1} alignItems="center">
            {changes.map( (change, index) => {
              return (
                <React.Fragment key={index+change.id+change.fieldName}>
                  <Grid item xs={6} >
                    <DiffDialog title={"Revert changes on field: "+change.fieldName} before={change.currentValue} after={change.changeToValue} align={true} />
                  </Grid>
                  <Grid item xs={6} >
                    {change.fieldName}
                  </Grid>
                </React.Fragment>
              )
            })}
            <Grid item xs={12}>
              <Button disabled={revertLoading} fullWidth variant="contained" color="primary" onClick={revert}>Revert changes</Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  )
}