

// Local storage structure:
// Eight keys stored with different keys:
// Course, Chapter, Page*, Alternative
const LOCAL_STORAGE_KEY = {
  course: "COURSE",
  chapter: "CHAPTER",
  page: "PAGE",
  pageSingle: "PAGESINGLE",
  pageRepeat: "PAGEREPEAT",
  pageMultiple: "PAGEMULTIPLE",
  pageInformation: "PAGEINFORMATION",
  pageFreetext: "PAGEFREETEXT",
  alternative: "ALTERNATIVE",
}

const LOCAL_STORAGE_ERROR = {
  course: "COURSEERROR",
  chapter: "CHAPTERERROR",
  page: "PAGEERROR",
}


function getStorageData(key) {
  return JSON.parse(localStorage.getItem(key)) || null
}

function checkIfInLocalStorage(id, storageData) {
  if(storageData === null || storageData === undefined) {
    return false
  }
  if(storageData[id?.toString()] === undefined) {
    return false
  }
  return true
}
// Example of json for course:
// course : {
//   1: {
//       timestamp: DATETIME
//       data: courseData
//   }
// }

// Save
function saveToLocalStorage(key, id, data ) {
  // Run this when the component is unmounting
  let oldStorage = JSON.parse(localStorage.getItem(key)) || {}
  if(id === undefined || key === undefined || data.undefined) {
    console.log("id, key or data is undefined")
    return
  }
  oldStorage[id.toString()] = {
    timestamp: new Date(),
    ...oldStorage[id.toString()],
    data: data,
  }
  oldStorage = JSON.stringify(oldStorage)
  localStorage.setItem(key, oldStorage)
}


function deleteFromLocalStorage(key, id) {
  const oldStorage = JSON.parse(localStorage.getItem(key)) || null
  if(oldStorage === null) {
    return null
  }
  delete oldStorage[id]
  localStorage.setItem(key, JSON.stringify(oldStorage))
}

function getFromLocalStorage(key, id) {
  // Get data from local storage if it is present.
  const oldStorage = JSON.parse(localStorage.getItem(key)) || null
  if(oldStorage === null) {
    return null
  }
  if(oldStorage[id?.toString()] === undefined) {
    return null
  }
  // Do a check to see if it is old and remove it from storage
  const timestamp = oldStorage[id.toString()].timestamp
  const dateDifferenceInTime = new Date().getTime() - new Date(timestamp).getTime()
  const dateDifferenceInDays = dateDifferenceInTime / (1000 * 3600 * 24)

  if(dateDifferenceInDays > 1) {
    delete oldStorage[id]
    localStorage.setItem(key, JSON.stringify(oldStorage))
    return null
  }
  // If not null, return the object
  return oldStorage[id]
}

function cleanLocalStorage() {
// If the local storage entry is older than 1 day, remove it.
  const now = new Date().getTime()
  const oneDay = 1000*3600*24
  for (const [key, value] of Object.entries(LOCAL_STORAGE_KEY)) {
    const storage = JSON.parse(localStorage.getItem(value)) || {}
    for (const [id, data] of Object.entries(value)) {
      const timestamp = new Date(data.timestamp).getTime()
      const diff = (now - timestamp)/oneDay
      // If over one day old
      if(diff > 1) {
        delete storage[id]
      }
    }
    localStorage.setItem(key, JSON.stringify(storage))
  }
}


export { LOCAL_STORAGE_KEY, deleteFromLocalStorage, getFromLocalStorage, saveToLocalStorage, cleanLocalStorage, LOCAL_STORAGE_ERROR, getStorageData, checkIfInLocalStorage }