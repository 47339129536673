import { useMutation } from "@apollo/client"
import { useSnackbar } from "notistack"
import { useParams } from "react-router-dom"
import GoToNew from "../utils/GoToNew"
import { DELETE_COURSE, DELETE_COURSE_CHAPTER, DELETE_COURSE_CHAPTER_PAGE, GET_COURSE_TREE } from "../gql"

const useDeleteMutation = (type) => {
  const { courseid, chapternumber, pagenumber } = useParams()
  const courseId = parseInt(courseid)
  const chapterNumber = parseInt(chapternumber)
  const pageNumber = parseInt(pagenumber)
  const { enqueueSnackbar } = useSnackbar()
  let mutation
  let returnData
  if(type === "COURSE") {
    mutation = DELETE_COURSE
    returnData = "deleteCourse"
  }

  if(type === "CHAPTER") {
    mutation = DELETE_COURSE_CHAPTER
    returnData = "deleteChapter"
  }

  if(type === "PAGE") {
    mutation = DELETE_COURSE_CHAPTER_PAGE
    returnData = "deletePage"
  }
  const refetchQuery = courseId
    ? [{ query:GET_COURSE_TREE,
      variables: { id: courseId } }]
    : []

  return useMutation(mutation, {
    refetchQueries: refetchQuery,
    onCompleted(data) {
      if (data) {
        const results = data[returnData]
        if (results.isValid) {
          pageNumber ? GoToNew({ id: courseId, chapterNumber: chapterNumber }) : chapterNumber ? GoToNew({ id: courseId }) : GoToNew({ overview: true })
        }
        else {
          results.errors.forEach(error => {
            enqueueSnackbar(error.errorMessage, { variant: "error" })
          })
        }
      } else {
        console.log("not deleted")
        enqueueSnackbar("Delete of " + type.toLowerCase() + " failed", { variant: "error" })
      }
    },
  })
}

export default useDeleteMutation