import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  from,
} from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { onError } from "@apollo/client/link/error"
import { RetryLink } from "@apollo/link-retry"
import React from "react"
import { useAuth0 } from "./oauth"

const AuthorizedApolloProvider = ({ children }) => {
  const { getTokenSilently } = useAuth0()

  const httpLink = new HttpLink({
    uri: "https://" + process.env.REACT_APP_API_URL + "graphql", // your URI here...
  })

  // const wsLink = new WebSocketLink({
  //   uri: "wss://" + process.env.REACT_APP_API_URL + "graphql",
  //   options: {
  //     reconnect: true,
  //     reconnectionAttempts: 2,
  //     connectionParams: async () => {
  //       const token = await getTokenSilently()
  //       if (token) {
  //         return { authToken: `${token}` }
  //       }
  //       return {}
  //     },
  //   },
  // })

  const authLink = setContext(async () => {
    const token = await getTokenSilently()
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  })

  const retryLink = new RetryLink({
    delay: {
      initial: 300,
      max: Infinity,
      jitter: true,
    },
    attempts: {
      max: 5,
      retryIf: (error, _operation) => !!error,
    },
  })

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      //enqueueSnackbar("Something went wrong when executing server call, please try again. If this problem persist, contact IT.", { variant: "error" })

      graphQLErrors.forEach(({ message, locations, path }) => {
        console.log(locations)
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      })
    }
    if (networkError) {
      //enqueueSnackbar("Something went wrong when contacting the server. Please update the page. If this problem persist, contact IT.", { variant: "error" })
      console.log(`[Network error]: ${networkError}`)
    }
  })

  // const splitLink = split(
  //   ({ query }) => {
  //     const definition = getMainDefinition(query)
  //     return (
  //       definition.kind === "OperationDefinition"
  //       && definition.operation === "subscription"
  //     )
  //   },
  //   wsLink,
  //   authLink.concat(httpLink)
  // )

  const apolloClient = new ApolloClient({
    link: from([retryLink, errorLink, authLink.concat(httpLink)]),
    cache: new InMemoryCache(),
    connectToDevTools: true,
  })

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
}

export default AuthorizedApolloProvider
