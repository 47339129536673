/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useReducer, useCallback } from "react"
import GoToNew from "../../utils/GoToNew"
import {
  deleteFromLocalStorage,
  getFromLocalStorage,
  saveToLocalStorage,
  LOCAL_STORAGE_KEY,
  LOCAL_STORAGE_ERROR,
} from "../../utils/localstorage"
import { useParams } from "react-router-dom"
import { usePageUnsavedDispatch } from "../../contexts/courseTree/PageUnsavedStateContext"
import { useMutation } from "@apollo/client"
import {
  GET_COURSE_CHAPTER_BY_NUMBER,
  GET_COURSE_TREE,
  UPDATE_COURSE_PAGE,
} from "../../gql"
import rules from "../../course-rules"
import { useCourseState } from "../../contexts"

function pageDataReducer(state, action) {
  switch (action.type) {
    case "id":
      return { ...state, id: action.id }
    case "maximumScore":
      return { ...state, maximumScore: action.maximumScore }
    case "pageType":
      return { ...state, pageType: action.pageType }
    case "content":
      return { ...state, content: action.content }
    case "question":
      return { ...state, question: action.question }
    case "response":
      return { ...state, response: action.response }
    case "number":
      return { ...state, number: action.number }
    case "orderAlternativesRandom":
      return {
        ...state,
        orderAlternativesRandom: action.orderAlternativesRandom,
      }
    case "excludeFromTest":
      return { ...state, excludeFromTest: action.excludeFromTest }
    case "repeat":
      return { ...state, repeat: action.repeat }
    case "alternative":
      return { ...state, changedAlternative: true }
    case "alternatives":
      return { ...state, alternatives: action.alternatives }
    case "all":
      return { ...action.data }
    default:
      break
  }
}

const usePageState = () => {
  const { courseid, chapternumber, pagenumber } = useParams()
  const courseId = parseInt(courseid)
  const chapterNumber = parseInt(chapternumber)
  const pageNumber = parseInt(pagenumber)

  const [changeFields, changeFieldsDispatch] = useReducer(pageDataReducer, {})
  const [changedNumbers, setChangeNumbers] = useState()
  const [pageData, dispatch] = useReducer(pageDataReducer)
  const [pageErrors, setPageErrors] = useState([])
  const courseState = useCourseState()
  const [ruleList] = useState(rules(courseState.courseState))
  const [fieldRules, setFieldRules] = useState(ruleList?.page)

  const changesDispatch = usePageUnsavedDispatch()

  // Save to localstorage
  useEffect(() => {
    if (
      Object.keys(changeFields).length !== 0 &&
      changeFields.constructor === Object &&
      Object.keys(pageData).length !== 0 &&
      pageData.constructor === Object
    ) {
      saveToLocalStorage(LOCAL_STORAGE_KEY.page, pageData.id, changeFields)
    }
  }, [pageData, changeFields])

  // If numbers are changed on save, redirect to correct page
  useEffect(() => {
    if (
      changedNumbers &&
      Object.keys(pageData).length !== 0 &&
      pageData.constructor === Object
    ) {
      setChangeNumbers()
      GoToNew({
        id: courseId,
        chapterNumber: chapterNumber,
        pageNumber: changedNumbers,
      })
    }
  }, [changedNumbers, chapterNumber, courseId, pageData])

  useEffect(() => {
    setFieldRules(rules(courseState.courseState)?.page)
  }, [courseState])

  // This updates the state and merges it from localstorage
  const updateData = useCallback((data) => {
    if (data) {
      const pageData = { ...data.pageByCourseIdChapterNumberPageNumber }
      const storageData = getFromLocalStorage(
        LOCAL_STORAGE_KEY.page,
        pageData.id
      )
      const errorData = getFromLocalStorage(
        LOCAL_STORAGE_ERROR.page,
        pageData.id
      )
      setPageErrors(errorData ? errorData.data : [])
      changeFieldsDispatch({ type: "all", data: {} })
      if (storageData) {
        changeFieldsDispatch({ type: "all", data: storageData.data })
        dispatch({
          type: "all",
          data: {
            ...pageData,
            ...storageData.data,
          },
        })
      } else {
        dispatch({ type: "all", data: pageData })
      }
    } else {
      dispatch({ type: "all", data: {} })
    }
  }, [])

  const setBlockData = useCallback(
    (data, name) => {
      changeFieldsDispatch({ type: name, [name]: data })
      dispatch({ type: name, [name]: data })
    },
    [changeFieldsDispatch, dispatch]
  )

  const setChangedState = useCallback(
    (unsaved, pageid) => {
      changesDispatch({ type: "PAGE", id: pageid, unsaved: unsaved })
    },
    [changesDispatch]
  )

  const notifyChangeAlternatives = useCallback(() => {
    if (changeFields.changedAlternative !== true) {
      changeFieldsDispatch({ type: "alternative" })
    }
  }, [changeFields.changedAlternative])

  useEffect(() => {
    try {
      if (
        !(
          Object.keys(changeFields).length === 0 &&
          changeFields.constructor === Object
        )
      ) {
        if (pageData?.id) {
          setChangedState(true, pageData.id)
        }
      }
    } catch {
      // fail gracefully, this is OK
    }
  }, [changeFields, setChangedState, pageData])

  const createVariables = () => {
    const variables = { variables: {} }
    variables.variables.id = pageData.id
    variables.variables.excludeFromTest = pageData.excludeFromTest
    variables.variables.orderAlternativesRandom =
      pageData.orderAlternativesRandom
    Object.keys(changeFields).forEach(function (key) {
      variables.variables[key] = changeFields[key]
    })
    const alternativesToUpdate = []
    pageData.alternatives.forEach((alternative) => {
      const unsavedAlternative = getFromLocalStorage(
        LOCAL_STORAGE_KEY.alternative,
        alternative.id
      )?.data
      if (unsavedAlternative) {
        unsavedAlternative.id = alternative.id
        if (!unsavedAlternative.number)
          unsavedAlternative.number = alternative.number
        alternativesToUpdate.push(unsavedAlternative)
      }
    })
    if (alternativesToUpdate.length !== 0) {
      variables.variables["alternatives"] = alternativesToUpdate
    }
    return variables
  }

  const [updateCoursePage, { loading: muationLoading, error: errorMutation }] =
    useMutation(UPDATE_COURSE_PAGE, {
      refetchQueries: [
        { query: GET_COURSE_TREE, variables: { id: courseId } },
        {
          query: GET_COURSE_CHAPTER_BY_NUMBER,
          variables: { CourseId: courseId, ChapterNumber: chapterNumber },
        },
      ],
    })

  const saveHandler = async (urlState, refetch) => {
    const data = await updateCoursePage(createVariables())
    const content = data.data.updatePage
    const tempErrors = []
    if (content) {
      if (content.pageValidationResult.isValid) {
        deleteFromLocalStorage(LOCAL_STORAGE_KEY.page, pageData.id)
        if (changeFields["number"]) {
          setChangeNumbers(changeFields["number"])
        }
        setChangedState(false, urlState.pageid)
      } else {
        content.pageValidationResult.errors.forEach((error) =>
          tempErrors.push(error)
        )
      }
      if (content.alternativeValidationResult.isValid) {
        const alternativesValid = content.coursePage.alternatives
        alternativesValid.forEach((alternative) => {
          deleteFromLocalStorage(LOCAL_STORAGE_KEY.alternative, alternative.id)
        })
      } else {
        setChangedState(true, urlState.pageid)
        content.alternativeValidationResult.errors.forEach((error) =>
          tempErrors.push(error)
        )
      }
      saveToLocalStorage(LOCAL_STORAGE_ERROR.page, urlState.pageid, tempErrors)
      setPageErrors(tempErrors)
      const data = await refetch()
      dispatch({
        type: "all",
        data: data.data.pageByCourseIdChapterNumberPageNumber,
      })
    }
  }

  const resetFromCloud = async (urlState, refetch) => {
    deleteFromLocalStorage(LOCAL_STORAGE_KEY.page, pageData.id)
    pageData.alternatives.forEach((alternative) => {
      deleteFromLocalStorage(LOCAL_STORAGE_KEY.alternative, alternative.id)
    })
    changeFieldsDispatch({ type: "all", data: {} })
    deleteFromLocalStorage(LOCAL_STORAGE_ERROR.page, pageData.id)
    setPageErrors([])
    const data = await refetch()
    dispatch({
      type: "all",
      data: data.data.pageByCourseIdChapterNumberPageNumber,
    })
    setChangedState(false, urlState.pageid)
  }

  return {
    courseId,
    chapterNumber,
    pageNumber,
    pageErrors,
    changeFields,
    pageData,
    muationLoading,
    errorMutation,
    fieldRules,
    updateData,
    setBlockData,
    notifyChangeAlternatives,
    saveHandler,
    resetFromCloud,
  }
}

export { usePageState }
