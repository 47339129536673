import React from "react"
import { Tooltip } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import WarningIcon from "@material-ui/icons/Warning"
import { orange } from "@material-ui/core/colors"

const useStyles = makeStyles(({ spacing }) => ({
  recalculateButton: {
    minWidth: "12rem",
  },
  warning: {
    color: orange[600],
    marginLeft: spacing(2),
  },
}))

const UnsavedChangesIcon = ({ text }) => {
  const classes = useStyles()

  return (
    <Tooltip title={text}>
      <WarningIcon className={classes.warning} />
    </Tooltip>
  )
}

export default UnsavedChangesIcon