import { gql } from "@apollo/client"

export const CREATE_COURSE_ALTERNATIVE = gql`
	mutation (
		$coursePageId: Int
		$response: String
		$text: String
		$points: Int
		$number: Int!
	) {
		createCourseAlternative(
			courseAlternative: {
				coursePageId: $coursePageId
				response: $response
				text: $text
				points: $points
				number: $number
			}
		) {
			errors {
				errorCode
				errorMessage
				propertyName
				severity
			}
			isValid
		}
	}
`

export const CREATE_CHAPTER = gql`
	mutation ($courseId: Int!, $name: String) {
		createChapter(courseId: $courseId, name: $name) {
			validationResult {
				errors {
					errorCode
					errorMessage
					propertyName
					severity
				}
				isValid
			}
			id
			chapterNumber
		}
	}
`

export const CREATE_PAGE = gql`
	mutation ($courseId: Int!, $chapternumber: Int!, $pageType: PageType!) {
		createPage(
			courseId: $courseId
			chapterNumber: $chapternumber
			pageType: $pageType
		) {
			validationResult {
				errors {
					errorCode
					errorMessage
					propertyName
					severity
				}
				isValid
			}
			id
			chapterNumber
			pageNumber
		}
	}
`

export const CREATE_COURSE = gql`
	mutation ($name: String, $type: CourseCategoryType!, $categoryId: Int!) {
		createCourse(name: $name, type: $type, categoryId: $categoryId) {
			validationResult {
				errors {
					errorCode
					errorMessage
					propertyName
					severity
				}
				isValid
			}
			id
		}
	}
`

export const CREATE_RESOURCE = gql`
	mutation ($name: String!, $url: String!) {
		createResource(resource: { name: $name, url: $url }) {
			validationResult {
				errors {
					errorCode
					errorMessage
					propertyName
					severity
				}
				isValid
			}
			id
		}
	}
`

export const ADD_RESOURCE_TO_COURSE = gql`
	mutation ($courseId: Int!, $resourceId: Int!) {
		addResourceToCourse(courseId: $courseId, resourceId: $resourceId)
	}
`

export const CREATE_CACHE_COURSE = gql`
	fragment newCourse on Course {
		id
		name
		guid
		version
		state
		categoryType
		approvedDate
		approvedText
	}
`

export const CREATE_CACHE_CHAPTER = gql`
	fragment newChapter on CourseChapter {
		id
		name
		number
		courseId
	}
`
export const CREATE_CACHE_PAGE = gql`
	fragment newPage on CoursePage {
		content
		question
		response
		excludeFromTest
		id
		maximumScore
		number
		pageType
		repeat
		courseChapterId
		alternatives {
			id
			text
			response
			points
			coursePageId
		}
	}
`

export const CREATE_CACHE_ALTERNATIVE = gql`
	fragment newAlternative on CourseAlternative {
		id
		text
		response
		points
		coursePageId
	}
`

export const CREATE_TEST_CHAPTERS = gql`
	mutation ($courseId: Int!) {
		generateTestChaptersForCourse(courseId: $courseId) {
			isValid
			courseValidation {
				errors {
					errorMessage
				}
			}
		}
	}
`
