import { makeStyles } from "@material-ui/core/styles"
import orange from "@material-ui/core/colors/orange"


const stylesAddFab = makeStyles({
  dialogContent: {
    minWidth: "30rem",
  },
  speedDial: {
    pointerEvents: "none !important",
    "&:hover": {
      pointerEvents: "auto",
    },
  },
})

export const stylesResetFab = makeStyles({
  fab: {
    pointerEvents: "auto",
  },
})


const stylesCreateCourseFab = makeStyles((theme) => ( {
  fab: {
    display: "flex",
    position: "fixed",
    bottom: theme.spacing(2),
    right: "15%",
    margin: theme.spacing(1),
    zIndex: 99,
  },
  dialogContent: {
    minWidth: "30rem",
  },
}))

const stylesFabBlock = makeStyles((theme) => ( {
  fab: {
    alignSelf: "flex-end",
    height: "100%",
    margin: theme.spacing(1),
    pointerEvents: "auto",
  },
  fabBlock: {
    display: "flex",
    position: "fixed",
    bottom: theme.spacing(2),
    zIndex: 99,
    pointerEvents: "none",
    right: "20px",
  },

  chip: {
    backgroundColor: "#fafafa",
  },
}))

const stylesSaveFab = makeStyles(({ palette }) => ({
  warning: {
    backgroundColor: orange[600],
    pointerEvents: "auto",
    "&:hover": {
      backgroundColor: orange[800],
    },
    color: palette.common.white,
  },
  standard: {
    backgroundColor: palette.primary.main,
    pointerEvents: "auto",
    "&:hover": {
      backgroundColor: palette.primary.dark,
    },
    color: palette.common.white,
  },
})
)


const generateStyleFab = makeStyles(({ palette }) => ({
  warning: {
    backgroundColor: orange[600],
    pointerEvents: "auto",
    "&:hover": {
      backgroundColor: orange[800],
    },
    color: palette.common.white,
  },
  standard: {
    backgroundColor: palette.primary.main,
    pointerEvents: "auto",
    "&:hover": {
      backgroundColor: palette.primary.dark,
    },
    color: palette.common.white,
  },
})
)


const publishStyleFab = makeStyles(({ palette }) => ({
  warning: {
    backgroundColor: orange[600],
    pointerEvents: "auto",
    "&:hover": {
      backgroundColor: orange[800],
    },
    color: palette.common.white,
  },
  standard: {
    backgroundColor: palette.primary.main,
    pointerEvents: "auto",
    "&:hover": {
      backgroundColor: palette.primary.dark,
    },
    color: palette.common.white,
  },
})
)

export { stylesAddFab, stylesCreateCourseFab, stylesFabBlock, stylesSaveFab, generateStyleFab, publishStyleFab }