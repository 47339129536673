import React from "react"
import { ReactComponent as Logo } from "../../assets/NHILogoVertical.svg"

function NHILogo({ classes }) {

  return (
    <Logo className={classes.logo} />
  )
}

export default NHILogo