import React from "react"
import { Chip, makeStyles, Tooltip } from "@material-ui/core"

const useStyles = makeStyles(({ palette }) => ({
  color: ({ state }) => {
    const _color = {
      public: palette.success.dark,
      development: palette.warning.dark,
      staged: palette.info.dark,
      deleted: palette.error.dark,
    }

    return {
      color: palette.common.white,
      backgroundColor: _color[state.toLowerCase()],
      marginLeft: "10px",
    }
  },
}))

const VersionChip = ({ label, state }) => {
  const classes = useStyles({ state })

  return (
    <Tooltip title={`Course state: ${state.toLowerCase()}`}>
      <Chip size="small" label={`${label}`} className={classes.color} />
    </Tooltip>
  )
}
export default VersionChip
