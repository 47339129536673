import React from "react"
import { Typography, Grid, makeStyles } from "@material-ui/core"

import UnsavedChangesIcon from "./Alert/UnsavedChangesIcon"

const useStyles = makeStyles(({ spacing }) => ({
  titleContainer: {
    margin: spacing(2, 0),
  },
  subTitle: {
    marginLeft: spacing(2),
  },
}))

const FormHeader = ({ header, unsavedChanges = false, subHeadings = [] }) => {
  const classes = useStyles()
  const headings = subHeadings.filter(
    (p) => p.value !== undefined && p.value !== null
  )

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      className={classes.titleContainer}
    >
      <Grid item>
        <Typography variant="h2">
          {header}
          {unsavedChanges && (
            <UnsavedChangesIcon text={"You have unsaved changes"} />
          )}
        </Typography>
      </Grid>

      {headings ? (
        <Grid item>
          {headings.map((sh) => (
            <Typography
              key={sh.label}
              variant="h4"
              component="span"
              className={classes.subTitle}
            >
              {sh.label} <strong>{sh.value}</strong>
            </Typography>
          ))}
        </Grid>
      ) : null}
    </Grid>
  )
}

export default FormHeader
