import React, { useContext, useEffect } from "react"

import { useLazyQuery } from "@apollo/client"
import { Button, LinearProgress, List, ListSubheader, makeStyles } from "@material-ui/core"

import { Alert } from "../../Alert/Alert"
import { v4 as uuidv4 } from "uuid"
import ValidationLinkListItem from "./ValidationLinkListItem"
import { VALIDATE_RESOURCES, VALIDATE_COURSE_LINKS } from "../../../gql"
import { urlContext } from "../../../hooks/urlHooks"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}))

const getQuery = (type) => {
  switch(type) {
    case "RESOURCE":
      return VALIDATE_RESOURCES
    case "COURSE":
      return VALIDATE_COURSE_LINKS
    default:
      break
  }
}


const ValidationLinkList = ( { type, courseid }) => {
  const classes = useStyles()
  const { state } = useContext(urlContext)
  const [validity, setValidity] = React.useState()

  const [validateResources, { loading, data, refetch }] = useLazyQuery(getQuery(type),
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
    })

  const createCourseList = React.useCallback((data) => {

    const courseValid = []
    const courseInvalid = []

    for(const link of data.checkLinkValidityInCourse.courseValidation) {
      if(link.statusCode === "OK") {
        courseValid.push( (<ValidationLinkListItem key={uuidv4()} courseid={courseid} resource={link} type="course"></ValidationLinkListItem>))
      } else {
        courseInvalid.push( (<ValidationLinkListItem key={uuidv4()} courseid={courseid} resource={link} type="course"></ValidationLinkListItem>))
      }
    }
    for (const link of data.checkLinkValidityInCourse.chapterValidation) {
      if(link.statusCode === "OK") {
        courseValid.push( (<ValidationLinkListItem key={uuidv4()} courseid={courseid} resource={link} type="chapter"></ValidationLinkListItem>))
      } else {
        courseInvalid.push( (<ValidationLinkListItem key={uuidv4()} courseid={courseid} resource={link} type="chapter"></ValidationLinkListItem>))
      }
    }
    for ( const link of data.checkLinkValidityInCourse.pageValidation) {
      if(link.statusCode === "OK") {
        courseValid.push( (<ValidationLinkListItem key={uuidv4()} courseid={courseid} resource={link} type="page"></ValidationLinkListItem>))
      } else {
        courseInvalid.push( (<ValidationLinkListItem key={uuidv4()} courseid={courseid} resource={link} type="page"></ValidationLinkListItem>))
      }
    }
    for (const link of data.checkLinkValidityInCourse.alternativeValidation) {
      if(link.statusCode === "OK") {
        courseValid.push( (<ValidationLinkListItem key={uuidv4()} courseid={courseid} resource={link} type="alternative"></ValidationLinkListItem>))
      } else {
        courseInvalid.push( (<ValidationLinkListItem key={uuidv4()} courseid={courseid} resource={link} type="alternative"></ValidationLinkListItem>))
      }
    }
    return [...courseInvalid, ...courseValid]
  }, [courseid])

  const createResourceList = React.useCallback((data) => {
    //Sorting so the invalid is at the top
    const writeAndReadData = [...data.checkLinkResourceValidation]
    return writeAndReadData.sort( (a, b) => (a.isValid === b.isValid)? 0 : a.isValid? 1 : -1).map( (obj) => {
      return (<ValidationLinkListItem key={uuidv4()} resource={obj} ></ValidationLinkListItem>)
    })
  }, [])

  const createList = React.useCallback((data, type) => {
    switch(type) {
      case "RESOURCE":
        return createResourceList(data)
      case "COURSE":
        return createCourseList(data)
      default:
        return undefined
    }
  }, [createResourceList, createCourseList])


  const validateResourceHandler = () => {
    const variables = { variables: { id: parseInt(state?.courseid) } }
    if(data !== undefined) {
      console.log("refetching")
      refetch(variables)
    } else
      validateResources(variables)
  }

  useEffect(() => {
    if(data) {
      setValidity(createList(data, type))
    }
  }, [data, type, createList])


  const button = <Button fullWidth={true} onClick={validateResourceHandler}>{loading
    ? <>  Validating </>
    : type === "RESOURCE" ? "Validate Resources" : "Validate Links"}
  </Button>

  return (
    <>
      {button}
      {loading && <LinearProgress />}
      { data !== undefined
        ? <>
          <Alert severity="warning">Even if links give error they might be valid. Please check the errors manually</Alert>
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader component="div" id="validation-errors-list-subheader">
                { type === "RESOURCE" ? "Resource Link Validation" : "Course Link Validation" }
              </ListSubheader>
            }
            className={classes.root}
          >
            {validity}
          </List>
        </>
        : undefined }
    </>
  )
}

export default React.memo(ValidationLinkList)
