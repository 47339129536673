import React, { useReducer } from "react"

const PageUnsavedStateContext = React.createContext()
const PageUnsavedDispatchContext = React.createContext()

const pageUnsavedReducer = (state, action) => {
  const id = action.id?.toString()

  switch(action.type){
    case "PAGE":
      return {
        ...state,
        [id]: {
          unsaved: action.unsaved,
        },
      }
    case "SET":
      return { ...action.data }
    default:
      return { ...state }
  }
}

const PageUnsavedStateProvider = ({ children }) => {

  const [state, dispatch] = useReducer(pageUnsavedReducer, {})

  return (
    <PageUnsavedStateContext.Provider value={state}>
      <PageUnsavedDispatchContext.Provider value={dispatch}>
        {children}
      </PageUnsavedDispatchContext.Provider>
    </PageUnsavedStateContext.Provider>
  )
}

function usePageUnsavedState() {
  const context = React.useContext(PageUnsavedStateContext)
  if (context === undefined) {
    throw new Error("usePageUnsavedState must be used within a ")
  }
  return context
}

function usePageUnsavedDispatch() {
  const context = React.useContext(PageUnsavedDispatchContext)
  if (context === undefined) {
    throw new Error("useUnsavedChangesDispatch must be used within a UnsavedChangesProvider")
  }
  return context
}

export { PageUnsavedStateProvider ,usePageUnsavedDispatch, usePageUnsavedState }