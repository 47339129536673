import React from "react"

const StateContext = React.createContext()
const StateDispatchContext = React.createContext()

function stateReducer(state, action) {
  switch(action.type) {
    case "VALIDATE":
      return { ...state, validationPassed: action.validationPassed }
    case "STATE":
      return { ...state, courseState: action.courseState }
    case "NOCHANGES":
      return { ...state }
    default:
      return { courseState: action.courseState, courseSession: action.courseSession, courseName: action.courseName, courseVersion: action.courseVersion, validationPassed: action.validationPassed }
  }
}

function CourseStateProvider({ children }) {
  const [state, dispatch] = React.useReducer(stateReducer, { courseState: undefined, courseSession: undefined, courseVersion: undefined })
  return (
    <StateContext.Provider value={state}>
      <StateDispatchContext.Provider value={dispatch}>
        {children}
      </StateDispatchContext.Provider>
    </StateContext.Provider>
  )
}

function useCourseState() {
  const context = React.useContext(StateContext)
  if (context === undefined) {
    throw new Error("useState must be used within a StateProvider")
  }
  return context
}

function useCourseStateDispatch() {
  const context = React.useContext(StateDispatchContext)
  if (context === undefined) {
    throw new Error("useCountDispatch must be used within a StateProvider")
  }
  return context
}

export { CourseStateProvider, useCourseState, useCourseStateDispatch }