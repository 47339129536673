import React from "react"
import UnsavedChangesIcon from "../Alert/UnsavedChangesIcon"
import StyledTreeItem from "./StyledTreeItem"
import history from "../../utils/history"
import CourseTreeChapter from "./CourseTreeChapter"
import { useCourseUnsavedState } from "../../contexts/courseTree/CourseUnsavedStateContext"

const handleCourseTreeClick = (event, value) => {
  event.preventDefault()
  const href = `/course/${value}`
  history.push(href)
}

const CourseTreeCourse = ({ treeData }) => {
  const sortedChapters = treeData.chapters.slice().sort(function (a, b) { return (a.number - b.number) })
  const state = useCourseUnsavedState()
  return (
    <StyledTreeItem
      nodeId={"root"}
      labelText={treeData.name}
      labelInfo={state[treeData.id]?.unsaved ? <UnsavedChangesIcon text={"Unsaved changes to course information"} /> : null}
      onLabelClick={(e) => handleCourseTreeClick(e, treeData.id)}
    >
      {Array.isArray(sortedChapters) ? sortedChapters.map((node) => <CourseTreeChapter key={"chapter"+node.id} treeData={node} courseid={treeData.id} />) : null}
    </StyledTreeItem>
  )
}

export default React.memo(CourseTreeCourse)