import React from "react"

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Switch,
  TextField,
  IconButton
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { useMutation } from "@apollo/client"
import { useSnackbar } from "notistack"
import { Add as AddIcon } from "@material-ui/icons"

import { CREATE_RESOURCE, ADD_RESOURCE_TO_COURSE } from "../../gql"
import { isUrlValid } from "../../utils/validation"
import AddResourceLoading from "./AddResourceLoading"
import SearchResourceBar from "./SearchResourceBar"

const useStyles = makeStyles({
  dialogContent: {
    minWidth: "30rem",
  },
  dialog: {
    minWidth: "600px",
  },
})


const AddResourceBox = (props) => {
  const { courseId, refetch } = props
  const [open, setOpen] = React.useState(false)
  const [name, setName] = React.useState("")
  const [url, setUrl] = React.useState("")
  const [id, setId] = React.useState(null)
  const [search, setSearch] = React.useState(true)
  const [loading, setLoading] = React.useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleAdd = () => {
    setLoading(true)
    if(search) {
      addResourceToCourse({ variables: { courseId: courseId, resourceId: id } })
    } else {
      createResource({ variables: { name: name, url: url } })
    }
  }

  const handleNameChange = (event) => {
    setName(event.target.value)
  }

  const handleUrlChange = (event) => {
    setUrl(event.target.value)
  }

  const updateResource = (resource) => {
    setUrl(resource.url)
    setName(resource.name)
    setId(resource.id)
  }
  const searchSwitchHandler = (event) => {
    setSearch(event.target.checked)
  }

  const [createResource, { loading: createLoading }] = useMutation(CREATE_RESOURCE,
    {
      onCompleted(data) {
        if (data) {
          addResourceToCourse({ variables: { courseId: courseId, resourceId: data.createResource.id } })
          enqueueSnackbar("New resource added to database", { variant:"success" })
        } else {
          enqueueSnackbar("New resource added to database failed", { variant:"error" })
        }
      },
    })

  const [addResourceToCourse, { loading: addLoading }] = useMutation(ADD_RESOURCE_TO_COURSE,
    {
      onCompleted(data) {
        if (data) {
          setLoading(false)
          setOpen(false)
          refetch()
          enqueueSnackbar("Resource was successfully added to course", { variant: "success" })
        } else {
          enqueueSnackbar("Resource was successfully added to course failed", { variant: "error" })
        }
      },
    })
  return (
    <>
      <IconButton onClick={handleClickOpen}> <AddIcon /> </IconButton>
      <Dialog
        maxWidth="md"
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Add a resource</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText id="alert-dialog-description">
          </DialogContentText>
           Use existing resource <Switch checked={search} onChange={searchSwitchHandler} />
          { search && <SearchResourceBar callback={updateResource} /> }
          <TextField
            required
            margin="dense"
            id="name"
            label="Name"
            type="text"
            value={name}
            onChange={handleNameChange}
            disabled={search}
            fullWidth
          />
          <TextField
            required
            margin="dense"
            id="url"
            label="Url"
            type="url"
            value={url}
            error={!isUrlValid(url)}
            helperText="Url needs to start with http or https"
            onChange={handleUrlChange}
            fullWidth
            disabled={search}
          />
          <AddResourceLoading loading={loading} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAdd} disabled={createLoading || addLoading || url.length === 0 || name.length === 0} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AddResourceBox