import React from "react"

import {
  LinearProgress
} from "@material-ui/core"

import GeneralSkeleton from "./GeneralSkeleton"


const LoadingGeneralSkeleton = () => {
  return (
    <><LinearProgress /><GeneralSkeleton /></>
  )
}

export default LoadingGeneralSkeleton