
import { getStorageData, LOCAL_STORAGE_KEY, checkIfInLocalStorage } from "../../utils/localstorage"

const createUnsavedOverview = ( treeData ) => {
  const storageCourse = getStorageData(LOCAL_STORAGE_KEY.course)
  const storageChapter = getStorageData(LOCAL_STORAGE_KEY.chapter)
  const storagePage = getStorageData(LOCAL_STORAGE_KEY.page)
  const courseInLocal = checkIfInLocalStorage(treeData.id, storageCourse)
  const obj = {
    course: {
      [treeData.id]: {
        unsaved: courseInLocal,
      },
    },
    chapter: {},
    page: {},
  }

  treeData.chapters.forEach(chapter=> {
    obj.chapter[chapter.id] = {}
    obj.chapter[chapter.id].unsaved = checkIfInLocalStorage(chapter.id, storageChapter)
    chapter.pages.forEach(page => {
      obj.page[page.id] = {
        unsaved: checkIfInLocalStorage(page.id, storagePage),
      }
    })
  })

  return obj
}

export { createUnsavedOverview }