import React, { useEffect, useState } from "react"

import {
  FormHelperText,
  TableCell,
  TableRow,
  TextField,
  IconButton,
  Tooltip,
  LinearProgress,
} from "@material-ui/core"
import yellow from "@material-ui/core/colors/yellow"
import { makeStyles } from "@material-ui/core/styles"
import { useMutation } from "@apollo/client"
import { useSnackbar } from "notistack"
import BaloonBlock from "../Editors/BaloonBlock"
import { AlertDeleteDialog } from "../Dialogs"
import { DELETE_COURSE_ALTERNATIVE, GET_COURSE_PAGE_BY_NUMBER } from "../../gql"
import {
  getFromLocalStorage,
  saveToLocalStorage,
} from "../../utils/localstorage"
import { LOCAL_STORAGE_KEY } from "../../utils/localstorage"
import {
  Delete as DeleteIcon,
  DragIndicator as DragIcon,
} from "@material-ui/icons"
import { orange } from "@material-ui/core/colors"
import { Draggable } from "react-beautiful-dnd"

const useStyles = makeStyles(() => ({
  warning: {
    color: orange[600],
  },
  unsavedChanges: {
    backgroundColor: yellow[100],
  },
}))

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",

  // change background colour if dragging
  background: isDragging ? orange[600] : null,

  // styles we need to apply on draggables
  ...draggableStyle,
})

const CourseAlternative = (props) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const {
    alternative,
    excludePoints,
    courseState,
    rules,
    notifyChange,
    disablePoints,
    courseId,
    chapterNumber,
    pageNumber,
    orderAlternativesRandom,
    alternativeKey,
    index,
  } = props

  const [open, setOpen] = useState(false)
  const [textEdit, setTextEdit] = useState(false)
  const [responseEdit, setResponseEdit] = useState(false)
  const [alternativeData, setAlternativeData] = useState()
  const [internalChange, setInternalChange] = useState(false)

  useEffect(() => {
    setAlternativeData(() => {
      if (alternative) {
        const localChanges = getFromLocalStorage(
          LOCAL_STORAGE_KEY.alternative,
          alternative?.id
        )
        if (localChanges) {
          return {
            ...alternative,
            ...localChanges.data,
          }
        } else {
          return alternative
        }
      } else {
        return {}
      }
    })
  }, [alternative])

  const handleCustomChange = (data, name) => {
    const prevChanges = getFromLocalStorage(
      LOCAL_STORAGE_KEY.alternative,
      alternative.id
    )
    saveToLocalStorage(LOCAL_STORAGE_KEY.alternative, alternative.id, {
      ...prevChanges?.data,
      [name]: data,
    })
    setAlternativeData((prevState) => ({ ...prevState, [name]: data }))
    setInternalChange(true)
    notifyChange()
  }

  const handleChange = (event) => {
    let newVal = event.target.value
    const name = event.target.name
    if (event.target.type === "number") {
      newVal = parseInt(newVal)
      if (name === "number") {
        newVal = newVal > 1 ? newVal : 1
      }
    }
    handleCustomChange(newVal, name)
  }

  const [deleteCourseAlternative, { loading: deleteLoading }] = useMutation(
    DELETE_COURSE_ALTERNATIVE,
    {
      refetchQueries: [
        {
          query: GET_COURSE_PAGE_BY_NUMBER,
          variables: {
            courseId: courseId,
            chapterNumber: chapterNumber,
            pageNumber: pageNumber,
          },
        },
      ],
      onCompleted(data) {
        if (!data) {
          enqueueSnackbar("Deletion of alternative failed", {
            variant: "error",
          })
        }
      },
    }
  )

  const handleShowingDialog = (state) => {
    setOpen(state)
  }

  const handleOpen = () => {
    handleShowingDialog(true)
  }

  if (alternativeData === undefined) {
    return null
  }

  const cachedChanges = getFromLocalStorage(
    LOCAL_STORAGE_KEY.alternative,
    alternative?.id
  )
  const unsavedChanges = cachedChanges !== null || internalChange

  const errorText = (
    <FormHelperText error={true}>{alternative.textError}</FormHelperText>
  )
  const errorResponse = (
    <FormHelperText error={true}>{alternative.responseError}</FormHelperText>
  )
  // TextEdit & ResponseEdit is there because it is quite expensive to load the editor, only do it when a user click in the cell
  const text =
    textEdit && !rules.text ? (
      <BaloonBlock
        name={"text"}
        data={alternativeData.text ?? ""}
        focused={true}
        getDataCallback={handleCustomChange}
        disabled={rules?.text}
      ></BaloonBlock>
    ) : (
      <div dangerouslySetInnerHTML={{ __html: alternativeData.text }} />
    )

  const response =
    responseEdit && !rules.response ? (
      <BaloonBlock
        name={"response"}
        data={alternativeData.response ?? ""}
        focused={true}
        getDataCallback={handleCustomChange}
        disabled={rules?.response}
      ></BaloonBlock>
    ) : (
      <div dangerouslySetInnerHTML={{ __html: alternativeData.response }} />
    )

  const points = excludePoints ?? (
    <TableCell width="10%" align="center" size="small">
      <TextField
        size="small"
        name={"points"}
        type="number"
        value={alternativeData.points}
        onChange={handleChange}
        disabled={rules?.points || disablePoints}
      ></TextField>
    </TableCell>
  )

  return (
    <>
      <Draggable
        key={alternativeKey}
        draggableId={alternativeKey}
        index={index}
        isDragDisabled={orderAlternativesRandom && !rules?.number}
      >
        {(provided, snapshot) => (
          <TableRow
            className={unsavedChanges ? classes.unsavedChanges : null}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(
              snapshot.isDragging,
              provided.draggableProps.style
            )}
          >
            {deleteLoading && (
              <TableCell colSpan={6}>
                <LinearProgress />
              </TableCell>
            )}
            <TableCell
              width="50%"
              align="left"
              onClick={() => setTextEdit(true)}
            >
              {text} {alternative.textError && errorText}
            </TableCell>
            <TableCell
              width="30%"
              align="left"
              onClick={() => setResponseEdit(true)}
            >
              {response} {alternative.responseError && errorResponse}
            </TableCell>
            {points}
            {!orderAlternativesRandom && !rules?.number && (
              <TableCell width="5%">
                <DragIcon />
              </TableCell>
            )}
            <TableCell width="5%">
              {courseState.courseState !== "DEVELOPMENT" ||
              courseState.courseSession ? null : (
                <>
                  <Tooltip title={"Delete alternative"}>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={handleOpen}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                  <AlertDeleteDialog
                    handleShowing={handleShowingDialog}
                    open={open}
                    data={{ type: "Alternative", id: alternative.id }}
                    text="You are about to delete an alternative"
                    callback={deleteCourseAlternative}
                    variables={{ id: alternativeData.id }}
                  ></AlertDeleteDialog>
                </>
              )}
            </TableCell>
          </TableRow>
        )}
      </Draggable>
    </>
  )
}

export default CourseAlternative
