const createVariables = (mustHaveVariables, changes) => {
  const variables = { variables: {} }

  Object.keys(mustHaveVariables).forEach(function (key) {
    variables.variables[key] = mustHaveVariables[key]
  })

  Object.keys(changes).forEach(function (key) {
    variables.variables[key] = changes[key]
  })
  return variables
}

export default createVariables