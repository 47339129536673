import React from "react"

import { useMutation } from "@apollo/client"
import { Tooltip, TextField, Button, LinearProgress, Typography } from "@material-ui/core"

import { useSnackbar } from "notistack"
import { isUrlValid } from "../../utils/validation"
import { UPDATE_RESOURCE } from "../../gql"

const UpdateResource = ( { resource, closePopup, refetch }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [name, setName] = React.useState(resource.name)
  const [url, setUrl] = React.useState(resource.url)
  const [changed, setChanged] = React.useState(false)

  const handleNameChange = (event) => {
    setName(event.target.value)
    setChanged(true)
  }

  const handleUrlChange = (event) => {
    setUrl(event.target.value)
    setChanged(true)
  }

  const handleEdit = () => {
    editResource({ variables: { id: resource.id, name: name, url: url } })
  }

  const [editResource, { loading }] = useMutation(UPDATE_RESOURCE,
    {
      onCompleted(data) {
        if(data.updateResource.isValid){
          enqueueSnackbar("Updated resource successfully", { variant: "success" })
          closePopup()
          refetch()
        } else {
          enqueueSnackbar("Update resource failed", { variant: "error" })
        }
      },
    })

  return (
    <>
      { loading && <LinearProgress/>}
      {changed && <Typography> Changing the name or url will make changes to the same resources if it is connected to other courses </Typography>}

      <TextField
        required
        margin="dense"
        id="name"
        label="Name"
        type="text"
        value={name}
        onChange={handleNameChange}
        fullWidth
      />
      <TextField
        required
        margin="dense"
        id="url"
        label="Url"
        type="url"
        value={url}
        error={!isUrlValid(url)}
        helperText="Url needs to start with http or https"
        onChange={handleUrlChange}
        fullWidth
      />
      <Tooltip title="Updating this resource will update the resource for all courses">
        <Button variant="contained" color="primary" onClick={handleEdit}>
          Update
        </Button>
      </Tooltip>
    </>
  )
}

export default UpdateResource
