/* eslint-disable react/display-name */
import React from "react"
import MaterialTable from "material-table"
import VersionChip from "./VersionChip"
import history from "../../utils/history"
import CourseMenu from "./CourseMenu"
import CreateCourseButton from "./../Buttons/CreateCourseButton"
import { Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { contains } from "./customFilterSearch"

const useStyles = makeStyles(() => ({
  name: {
    fontSize: "1.25em",
  },
  menu: {
    paddingLeft: "10px",
  },
  nameDiv: {
    display: "flex",
  },
}))

export const createParsedDate = (unparsedDate) => {
  const date = new Date(Date.parse(unparsedDate))
  if (unparsedDate) {
    return `${(date.getDate() < 10 ? "0" : "") + date.getDate()}.${
      date.getMonth() + 1
    }.${date.getFullYear()} - ${
      (date.getHours() < 10 ? "0" : "") + date.getHours()
    }:${(date.getMinutes() < 10 ? "0" : "") + date.getMinutes()}`
  }
  return null
}

const CourseOverviewBaseTable = ({
  data,
  title,
  showCreateButton = true,
  type,
  refetch,
}) => {
  const classes = useStyles()

  const mutableData = data.map((o) => {
    const category =
      o.courseCategory.parent !== null
        ? `${o.courseCategory.parent.name} / ${o.courseCategory.name}`
        : o.courseCategory.name

    return {
      id: o.id,
      name: o.name,
      version: o.version,
      lastModified: o.lastModified,
      category: category,
      state: o.state,
      sessionCount: o.sessionCount,
    }
  })

  const handleRowClick = (_, rowData) => {
    const href = `/course/${rowData.id}`
    history.push(href)
  }

  return (
    <MaterialTable
      style={{ paddingRight: "20px", paddingLeft: "20px" }}
      options={{
        actionsColumnIndex: -1,
        pageSize: 10,
        pageSizeOptions: [10, 20, 30],
        filtering: true,
        search: false,
      }}
      title={
        <h1>
          {title} {showCreateButton && <CreateCourseButton type={type} />}{" "}
        </h1>
      }
      onRowClick={handleRowClick}
      columns={[
        {
          title: "Name",
          field: "name",
          render: (rowData) => (
            <div className={classes.nameDiv}>
              <Typography variant="body1" className={classes.name}>
                {rowData.name}{" "}
              </Typography>{" "}
              <VersionChip
                className={classes.chip}
                label={rowData.version}
                state={rowData.state}
              />{" "}
            </div>
          ),
        },
        {
          title: "Category",
          field: "category",
          render: (rowData) => (
            <Typography variant="body2">{rowData.category}</Typography>
          ),
          customFilterAndSearch: (term, rowData) =>
            contains(term, rowData.category),
        },
        {
          title: "Modified",
          field: "lastModified",
          filtering: false,
          render: (rowData) => createParsedDate(rowData.lastModified),
        },
      ]}
      actions={[
        (rowData) => ({
          // eslint-disable-next-line react/display-name
          icon: () => (
            <CourseMenu
              id={rowData.id}
              sessionCount={rowData?.sessionCount}
              courseName={rowData.name}
            />
          ),
        }),
      ]}
      data={mutableData}
    />
  )
}

export default CourseOverviewBaseTable
