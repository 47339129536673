import { createTheme as createMuiTheme } from "@material-ui/core/styles"

const theme = createMuiTheme({
  typography: {
    fontFamily: ["IBM Plex Sans", "sans-serif", "Roboto", "Helvetica"],
    h1: {
      fontSize: "2.75rem",
      fontWeight: "bold",
    },
    h2: {
      fontSize: "2.125rem",
      fontWeight: "bold",
    },
    h3: {
      fontSize: "1.5rem",
      fontWeight: "bold",
    },
    h4: {
      fontSize: "1.125rem",
    },
    h5: {
      fontSize: "1rem",
    },
    h6: {
      fontSize: "0.9rem",
      fontWeight: "bold",
    },
    subtitle1: {
      fontWeight: 700,
    },
    subtitle2: {
      fontWeight: 700,
    },
  },
  palette: {
    primary: {
      light: "#2f5774",
      main: "#1f394c",
      dark: "#0c161d",
      contrastText: "#ffffff",
    },
    secondary: {
      light: "#60DBE6",
      main: "#26cddd",
      dark: "#1ca4b0",
      contrastText: "#000000",
    },
    links: {
      main: "1eb4c2",
      hover: "19949f",
    },
  },
})

export { theme }
