import React from "react"

import { ListItem, ListItemIcon, ListItemSecondaryAction, Collapse, ListItemText, IconButton, Tooltip } from "@material-ui/core"
import { ArrowForward, ExpandLess, ExpandMore } from "@material-ui/icons"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import ErrorIcon from "@material-ui/icons/Error"
import { v4 as uuidv4 } from "uuid"
import { orange, green } from "@material-ui/core/colors"

import history from "../../../utils/history"


const getErrorText = (type, data) => {
  switch (type) {
    case "alternative":
      return `/chapter/${data.chapterNumber}/page/${data.pageNumber}`
    case "chapter":
      return `/chapter/${data.chapterNumber}`
    case "page":
      return `/chapter/${data.chapterNumber}/page/${data.pageNumber}`
    case "course":
      return "course"
    default:
      break
  }
}

const redirect = (type, data, courseid) => {
  let errorText = getErrorText(type, data)
  if (errorText === "course") {
    errorText = ""
  }
  const href = "/course/" + courseid + errorText
  history.push(href)
}


const ValidationLinkListItem = ({ resource, type, courseid }) => {
  const [open, setOpen] = React.useState(false)
  const status = resource.statusCode !== "OK" ? resource.statusCode === "REDIRECT" ? <ErrorIcon style={{ color: orange[600] }} /> : <ErrorIcon color="secondary" /> : <CheckCircleIcon style={{ color: green[600] }} />

  const handleClick = () => {
    setOpen(!open)
  }
  const dataMapped = (
    <>
      <ListItem key={uuidv4()}>
        <ListItemText>Status: {resource.statusCode} </ListItemText>
      </ListItem>
      <ListItem key={uuidv4()}>
        <ListItemText> <a target="_blank" rel="noopener noreferrer" href={resource.url} >{resource.url}</a> </ListItemText>
      </ListItem>
      {type
        && <ListItem key={uuidv4()}>
          <ListItemText>Location: {getErrorText(type, resource)} </ListItemText>
          <ListItemSecondaryAction>
            <Tooltip title={"Go to location"}>
              <IconButton edte="end" onClick={() => { redirect(type, resource, courseid) }}>
                <ArrowForward />
              </IconButton>
            </Tooltip>
          </ListItemSecondaryAction>
        </ListItem>
      }
    </>
  )

  return (
    <>
      <ListItem button onClick={handleClick} >
        <ListItemIcon>
          {status}
        </ListItemIcon>
        <ListItemText>
          {type === undefined ? resource.name : resource.url.length > 30 ? resource.url.slice(0, 30).concat("...") : resource.url }
        </ListItemText>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {dataMapped}
      </Collapse>
    </>
  )
}

export default React.memo(ValidationLinkListItem)