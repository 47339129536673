import { gql } from "@apollo/client"

export const UPDATE_COURSE_CHAPTER = gql`
  mutation(
    $id: Int!,
    $name: String,
    $createdBy: String,
    $courseId: Int,
    $number: Int,
    $percentToPass: Int,
    $maximumScore: Int,
    $chapterType: ChapterType) {
      updateChapter(
        courseChapter: {
          id: $id
          name: $name,
          createdBy: $createdBy
          courseId: $courseId
          number: $number
          percentToPass: $percentToPass
          maximumScore: $maximumScore
          chapterType: $chapterType
        }) {
          errors {
            errorCode,
            errorMessage,
            propertyName,
            severity
          },
          isValid
        }
    }
`

export const UPDATE_COURSE = gql`
  mutation(
    $id: Int!
    $state: CourseState
    $name: String
    $thumbnail: String
    $ingress: String
    $information: String
    $certificateType: CertificateType
    $hasJournal: Boolean
    $certificateTemplate: String
    $summaryContent: String
    $hours: Int
    $price: Int
    $courseCategory: CourseCategoryInput
    $paymentPosition: PaymentPositionType
    $paymentPageNumber: Int
    $paymentChapterNumber: Int
    $hTMLMeta: String
    $approvedDate: DateTime
    $approvedText: String
    ) {
    updateCourse(
      courseInput: {
        id: $id
        state: $state
        name: $name
        thumbnail: $thumbnail
        ingress: $ingress
        information: $information
        certificateType: $certificateType
        hasJournal: $hasJournal
        certificateTemplate: $certificateTemplate
        summaryContent: $summaryContent
        hours: $hours
        price: $price
        paymentPosition: $paymentPosition
        paymentPageNumber: $paymentPageNumber
        paymentChapterNumber: $paymentChapterNumber
        hTMLMeta: $hTMLMeta
        approvedDate: $approvedDate
        approvedText: $approvedText
        courseCategory: $courseCategory
      }
    ) {
    errors {
        errorCode,
        errorMessage,
        propertyName,
        severity
    },
    isValid
    }
    }
`

export const UPDATE_COURSE_PAGE = gql`
  mutation(
    $id: Int!
    $content: String
    $excludeFromTest: Boolean!
    $maximumScore: Int
    $question: String
    $repeat: Int
    $response: String
    $number: Int
    $alternatives: [CourseAlternativeInput]
    $pageType: PageType
    $orderAlternativesRandom: Boolean
  ) {
    updatePage(
      coursePage: {
        id: $id
        content: $content
        excludeFromTest: $excludeFromTest
        maximumScore: $maximumScore
        question: $question
        repeat: $repeat
        response: $response
        number: $number
        alternatives: $alternatives
        pageType: $pageType
        orderAlternativesRandom: $orderAlternativesRandom
      }
    ) {
      pageValidationResult {
        errors {
          errorCode,
          errorMessage,
          propertyName,
          severity
        },
        isValid
      }
      alternativeValidationResult {
        errors {
          errorCode,
          errorMessage,
          propertyName,
          severity
        },
        isValid
      }
      coursePage {
        id
        content
        excludeFromTest
        maximumScore
        question
        repeat
        response
        number
        pageType
        orderAlternativesRandom
        chapter {
          id
          name
        }
        alternatives {
          id
          text
          response
          points
          number
        }
      }
    }
  }
`

export const UPDATE_RESOURCE = gql`
  mutation ($name: String!, $url: String!, $id: Int) { updateResource(resource: {
    id: $id
    name: $name,
    url: $url
    }) {
    errors {
        errorCode,
        errorMessage,
        propertyName,
        severity
      },
      isValid
  } 
}`

export const PUBLISH_COURSE = gql`
  mutation ($id: Int!) {
    publishCourse(courseId: $id)
  }
`