import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { ListSubheader, List } from "@material-ui/core"
import ValidationListItem from "./ValidationListItem"
import { v4 as uuidv4 } from "uuid"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}))

const ValidationList = ( { validationData, courseid }) => {

  const classes = useStyles()
  const alternativeMappedData = validationData !== undefined && validationData.alternativeValidation.length !== 0
    ? validationData.alternativeValidation.map( (error) => {
      return (<ValidationListItem key={"alternative"+uuidv4()} courseid={courseid} type="alternative" data={error}> </ValidationListItem>)
    } )
    : null
  const pageMappedData = validationData !== undefined && validationData?.pageValidation.length !== 0
    ? validationData.pageValidation.map( (error) => {
      return (<ValidationListItem key={"page"+uuidv4()} courseid={courseid} type="page" data={error}> </ValidationListItem>)
    } )
    : null
  const chapterMappedData = validationData !== undefined && validationData?.chapterValidation.length !== 0
    ? validationData.chapterValidation.map((error) => {
      return (<ValidationListItem key={"chapter"+uuidv4()} courseid={courseid} type="chapter" data={error}> </ValidationListItem>)
    } )
    : null

  const courseData = validationData !== undefined && validationData?.courseValidation
    ? <ValidationListItem key={"course"+uuidv4()} type="course" courseid={courseid} data={validationData.courseValidation}></ValidationListItem>
    : null

  return(
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="validation-errors-list-subheader">
          Validation Errors
        </ListSubheader>
      }
      className={classes.root}
    >
      {courseData}
      {chapterMappedData}
      {pageMappedData}
      {alternativeMappedData}
    </List>
  )
}

export default React.memo(ValidationList)

