import React from "react"
import DownloadButton from "./DownloadButton"
import { useLazyQuery } from "@apollo/client"
import { GET_DOWNLOAD_CHAPTER } from "../../gql/get"
import { saveAs } from "file-saver"
import b64toBlob from "./b64toBlob"


const DownloadChapterButton = ( { id }) => {
  const [getDownload, { loading, error }] = useLazyQuery(GET_DOWNLOAD_CHAPTER, {
    variables: { id: id },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const { base64, filename } = data?.generateChapterDocx
      saveAs(b64toBlob(`data:application/zip;base64,${base64}`), `${filename}`)
    },
  })

  return <DownloadButton callback={getDownload} id={id} error={error} loading={loading} />
}

export default DownloadChapterButton