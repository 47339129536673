import { gql } from "@apollo/client"

export const GET_CACHE_COURSE_ALTERNATIVE_BY_ID = gql`
  fragment alternative on CourseAlternative {
      id
      coursePageId
  }
`

export const GET_COURSE_CHAPTER_BY_NUMBER = gql`
  query($CourseId: Int!, $ChapterNumber: Int!) {
    chapterByCourseAndNumber(courseId: $CourseId, chapterNumber: $ChapterNumber) {
      id
      name
      createdBy
      courseId
      number
      percentToPass
      maximumScore
      chapterType
    }
  }
`

export const GET_CACHE_COURSE_CHAPTER_BY_ID = gql`
  fragment Chapter on CourseChapter {
    id
    courseId
    number
    name
  }
`

export const GET_CATEGORIES = gql`
query($type: CourseCategoryType!) {
  categoriesByCourseType(type: $type) {
    id,
    name,
    guid
    subCategories {
      id
      guid
      name
    }
  }
}
`

export const COURSE_INFORMATION = gql`
  query($CourseId: Int!) {
    courseById(id: $CourseId) {
      id
      name
      created
      externalId
      price
      hours
      guid
      courseCategory {
        name
        id
        parent {
          name
          id
        }
      }
      hasJournal
      createdBy
      categoryType
      version
      hTMLMeta
      paymentPosition
      paymentPageNumber
      paymentChapterNumber
      state
      ingress
      summaryContent
      thumbnail
      information
      certificateType
      certificateTemplate
      approvedText
      approvedDate
    }
  }
`

export const GET_CACHE_COURSE_BY_ID = gql`
  fragment Course on Course {
    id
    name
    version
  }
`

export const ALL_COURSES = gql`
  query ($type: CourseCategoryType!, $getLastVersionOnly: Boolean!) {
    courses(type: $type, getLastVersionOnly: $getLastVersionOnly) {
      id
      name
      guid
      externalId
      version
      lastModified
      state
      sessionCount
      courseCategory {
        id
        name,
        parent {
          id
          name
        }
      }
    }
  }
`

export const GET_COURSES_BY_GUID = gql`
query ($guid: Uuid!) {
  coursesByGuid(guid: $guid){
      id
      name
      guid
      version
      lastModified
      state
      sessionCount
      courseCategory {
        id
        name
        parent {
          id
          name
        }
      }
    }
  }`

export const GET_COURSE_PAGE_BY_NUMBER = gql`
  query ($chapterNumber: Int!, $courseId: Int!, $pageNumber: Int!) {
    pageByCourseIdChapterNumberPageNumber(chapterNumber: $chapterNumber, courseId: $courseId, pageNumber: $pageNumber) {
      content,
      question,
      response,
      excludeFromTest,
      id,
      createdBy,
      maximumScore,
      number,
      pageType,
      repeat,
      courseChapterId,
      orderAlternativesRandom
      alternatives {
        coursePageId,
        id,
        text,
        response,
        points,
        number
      }
    }
  }
`

export const GET_COURSE_PAGE_BY_ID = gql`
  query ($id: Int!) {
    pageById(id: $id) {
      content,
      question,
      response,
      excludeFromTest,
      id,
      createdBy,
      maximumScore,
      number,
      pageType,
      repeat,
      courseChapterId,
      orderAlternativesRandom
      alternatives {
        coursePageId,
        id,
        text,
        response,
        points,
        number
      }
    }
  }
`


export const GET_CACHE_COURSE_PAGE_BY_ID = gql`
  fragment page on CoursePage {
      id
      courseChapterId
      number
      pageType
  }
`

export const GET_COURSE_TREE = gql`
  query ($id: Int!) {
    courseById(id: $id) {
      id,
      name,
      state,
      version,
      courseCategory {
        name
        id
        parent {
          name
          id
        }
      },
      newestCourse
      sessionCount,
      chapters {
        id,
        name,
        number,
        courseId,
        pages {
          id,
          number,
          pageType,
          courseChapterId
        }
      }
    }
  }
`

export const GET_HISTORY = gql`
  query(
    $Id: Int!
    $Type: TableType!
    $First: Int
    $Last: Int
    $After: String
    $Before: String
    $Order_by: HistorySort
    $Filter: HistoryFilter
  ) {
    history(
      id: $Id
      type: $Type
      first: $First
      last: $Last
      after: $After
      before: $Before
      order_by: $Order_by
      where: $Filter
    ) {
      edges {
        cursor
        node {
          id
          modified
          modifiedBy
          table
          valueAfter
          valueBefore
          changes
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
      }
    }
  }
`

export const GET_TOPLEVEL_HISTORY = gql`
  query(
    $Id: Int!
    $First: Int
    $Last: Int
    $After: String
    $Before: String
    $Order_by: HistorySort
    $Filter: HistoryFilter
  ) {
    topLevelHistory(
      id: $Id
      first: $First
      last: $Last
      after: $After
      before: $Before
      order_by: $Order_by
      where: $Filter
    ) {
      edges {
        cursor
        node {
          id
          modified
          modifiedBy
          table
          valueAfter
          valueBefore
          changes
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
      }
    }
  }
`
export const GET_DOWNLOAD_COURSE = gql`
  query ($id: Int!) {
    generateCourseDocx(id: $id) {
      filename
      base64
    }
  }
`

export const GET_DOWNLOAD_CHAPTER = gql`
  query ($id: Int!) {
    generateChapterDocx(id: $id) {
      filename
      base64
    }
  }
`

export const GET_RESOURCES_FOR_COURSE = gql`
  query ($courseId: Int!) {
    resourcesByCourseId(courseId: $courseId) {
        id,
        name,
        url
    }
  }
`

export const SEARCH_RESOURCE = gql`
  query ($searchString: String!) {
    searchResources(searchString: $searchString) {
      id
      name
      url
    }
  }
`