import React, { useContext, useEffect } from "react"
import Container from "@material-ui/core/Container"
import { urlContext } from "../hooks/urlHooks"
import CourseOverviewTable from "../components/CourseOverviewTable/CourseOverviewTable"

const CourseOverviewPage = ({ type }) => {
  const { dispatch } = useContext(urlContext)

  useEffect(() => {
    dispatch({ type: "course" })
  }, [dispatch])

  return (
    <Container maxWidth="xl">
      <CourseOverviewTable type={type}/>
    </Container>
  )
}

export default CourseOverviewPage