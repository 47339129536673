import React from "react"
import { Button, CircularProgress } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { green } from "@material-ui/core/colors"

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}))

const DownloadButton = ({ callback, loading, id, error }) => {
  const classes = useStyles()
  return (
    <>
      <Button
        color={error ? "secondary" : "primary"}
        onClick={() => callback()}
        disabled={loading || !id}
        variant="contained"
      >
        {error ? "An error has occured" : "Export Word"}{" "}
        {loading && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
      </Button>
    </>
  )
}

export default DownloadButton
