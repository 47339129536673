const usePageHandlers = (setBlockData) => {

  const excludeHandler = (event) => {
    setBlockData(event.target.checked, event.target.name)
  }

  const repeatHandler = (event) => {
    const num = parseInt(event.target.value)
    if (num >= 2) {
      setBlockData(num, event.target.name)
    } else {
      setBlockData(2, event.target.name)
    }
  }

  const pageTypeHandler = (event) => {
    const type = event.target.value

    if(type === "MULTIPLE" || type === "SINGLE" ) {
      setBlockData(type, event.target.name)
      setBlockData(0, "repeat")
    } else if( type === "REPEAT") {
      setBlockData(type, event.target.name)
      setBlockData(2, "repeat")
    }
  }

  const numberHandler = (event) => {
    const num = parseInt(event.target.value)
    if (num > 0) {
      setBlockData(num, event.target.name)
    }
  }

  const orderAlternativesRandomHandler = (event) => {
    setBlockData(event.target.checked, event.target.name)
  }

  return {
    excludeHandler,
    repeatHandler,
    pageTypeHandler,
    numberHandler,
    orderAlternativesRandomHandler,
  }
}

export default usePageHandlers