import React, { useContext, useEffect } from "react"

import { useLazyQuery } from "@apollo/client"
import { LinearProgress, Button, Accordion, AccordionSummary, AccordionDetails, Typography } from "@material-ui/core"
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons"
import { makeStyles } from "@material-ui/core/styles"

import { Alert } from "../Alert/Alert"
import ValidationList from "./Course/ValidationList"
import ValidationLinkList from "./Links/ValidationLinkList"
import { urlContext } from "../../hooks/urlHooks"
import { VALIDATE_COURSE } from "../../gql"
import { useCourseStateDispatch } from "../../contexts"

const useStyles = makeStyles((theme) => ({
  drawerElement: {
    marginbottom: "10px",
    width: "100%",
  },
  accordion: {
    marginbottom: "10px",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}))

const ValidationLayout = () => {
  const { state } = useContext(urlContext)
  const classes = useStyles()
  const courseStateDispatch = useCourseStateDispatch()

  const [validateCourse, { loading, data, refetch, networkStatus }] = useLazyQuery(VALIDATE_COURSE,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
    })


  const validateCourseHandler = () => {
    const variables = { variables: { id: parseInt(state?.courseid) } }
    if(data !== undefined) {
      refetch(variables)
    } else
      validateCourse(variables)
  }

  useEffect( () => {
    if(data){
      if(networkStatus === 7) {
        if(data?.validateCourse?.isValid) {
          courseStateDispatch({ type: "VALIDATE", validationPassed: true })
        } else
        {
          courseStateDispatch({ type: "VALIDATE", validationPassed: false })
        }
      }
    }

  },[courseStateDispatch, data, networkStatus])

  const button = <Button fullWidth={true} onClick={validateCourseHandler}>{loading
    ? <> Validating Course </>
    : "Validate Course"}</Button>
  return (
    <Accordion className={classes.accordion }TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className={classes.heading}>Validation</Typography>
      </AccordionSummary>
      <AccordionDetails >
        <div className={classes.drawerElement}>
          {data !== undefined && !data?.validateCourse?.isValid ? <Alert severity="error">Not all validations have passed</Alert> : null }
          {button}
          {data?.validateCourse?.isValid && <Alert severity="success">All validations passed</Alert> }
          {loading && <LinearProgress/>}
          { data !== undefined && !data?.validateCourse?.isValid ? <ValidationList className={classes.drawerElement} courseid={state.courseid} validationData={data.validateCourse}></ValidationList> : null }
          <ValidationLinkList className={classes.drawerElement} type="RESOURCE" courseid={state.courseid}> </ValidationLinkList>
          <ValidationLinkList className={classes.drawerElement} type="COURSE" courseid={state.courseid}></ValidationLinkList>
        </div>
      </AccordionDetails>
    </Accordion>
  )
}

export default ValidationLayout