import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Grid, Container } from "@material-ui/core"
import { CourseUnsavedStateProvider } from "../../contexts/courseTree/CourseUnsavedStateContext"
import { ChapterUnsavedStateProvider } from "../../contexts/courseTree/ChapterUnsavedStateContext"
import { PageUnsavedStateProvider } from "../../contexts/courseTree/PageUnsavedStateContext"


const useStyles = makeStyles(((theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
    marginTop: "10px",
  },
  mainGrid: {
    marginTop: theme.spacing(3),
    minHeight: "92vh",
  },
  sidebar: {
    position: "sticky",
    top: 0,
    overflowY: "auto",
    maxHeight: "92vh",
    marginBottom: "75px",
  },
}))))

const SkeletonEditLayout = (props) => {
  const { Content, Tree, Resource, Validation } = props
  const classes = useStyles()

  return (
    <CourseUnsavedStateProvider>
      <ChapterUnsavedStateProvider>
        <PageUnsavedStateProvider>
          <Container maxWidth="xl">
            <Grid container spacing={2} className={classes.mainGrid}>
              <Grid item xs={12} md={8}>
                {Content}
              </Grid>

              <Grid className={classes.sidebar} item xs={12} md={4}>
                {Tree}
                {Resource}
                {Validation}
              </Grid>
            </Grid>
          </Container>
        </PageUnsavedStateProvider>
      </ChapterUnsavedStateProvider>
    </CourseUnsavedStateProvider>
  )

}

export default SkeletonEditLayout