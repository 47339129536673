import React from "react"

const urlContext = React.createContext(null)
function stateReducer(state, action) {
  switch(action.type) {
    case "course":
      return { courseid: action.courseid, coursename: action.coursename }
    case "chapter":
      return { courseid: action.courseid, coursename: action.coursename, chapterid: action.chapterid, chapternumber: action.chapternumber, chaptername: action.chaptername }
    case "page":
      return { ...state, courseid: action.courseid, coursename: action.coursename, chapterid: action.chapterid, chapternumber: action.chapternumber, chaptername: action.chaptername, pagenumber: action.pagenumber, pageid: action.pageid, pagetype: action.pagetype }
    case "singlePage":
      return { ...state, pageid: action.pageid, pagenumber: action.pagenumber, pagetype: action.pagetype }
    default:
      return { courseid: undefined, coursename: undefined, chapterid: undefined, chapternumber: undefined, chaptername: undefined, pagenumber: undefined, pageid: undefined, pagetype: undefined, unsavedChanges: false }
  }
}

const useUrlParams = () => {
  const [state, dispatch] = React.useReducer(stateReducer, {
    courseid: undefined,
    coursename: undefined,
    chapterid: undefined,
    chapternumber: undefined,
    chaptername: undefined,
    pageid: undefined,
    pagetype: undefined,
    pagenumber: undefined,
  })

  return { state, dispatch }
}

export { urlContext, useUrlParams }