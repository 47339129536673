import React from "react"
import { Paper, Container } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import {
  Link
} from "react-router-dom"

const useStyles = makeStyles(((theme => ({
  root: {
    height: "80vh",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  paper: {
    color: theme.palette.text.secondary,
    margin: "auto",
    width: "100%",
    textAlign: "center",
    padding: "100px",
  },
}))))

const ErrorPage = () => {
  const classes = useStyles()
  return (
    <Container maxWidth="xl" className={classes.root}>
      <Paper className={classes.paper}>
        <h1>Something went wrong...</h1>
        <div><Link to="/">Click here</Link> to go back to overview for courses</div>
      </Paper>
    </Container>


  )
}

export default ErrorPage