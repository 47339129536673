import React from "react"
import { Paper, Tabs as MuiTabs, Tab, makeStyles } from "@material-ui/core"

const useStyles = makeStyles(({ spacing }) => ({
  tabs: {
    marginBottom: spacing(2),
  },
}))

const Tabs = ({ tabLabels = [], tab, setTab, ariaLabel = "simple tabs" }) => {
  const classes = useStyles()

  const handleTabChange = (_, newValue) => {
    if (setTab && typeof setTab === "function") {
      setTab(newValue)
    }
  }

  return (
    <Paper square className={classes.tabs}>
      <MuiTabs variant="scrollable" value={tab} onChange={handleTabChange} aria-label={ariaLabel}>
        {tabLabels.map(label => <Tab key={label} label={label} />)}
      </MuiTabs>
    </Paper>
  )
}

export default Tabs

