import { gql } from "@apollo/client"

export const DELETE_COURSE = gql`
  mutation($id: Int!) { 
      deleteCourse(courseId: $id) {
        errors {
          errorCode,
          errorMessage,
          propertyName,
          severity
        },
        isValid
      }
  }
`

export const DELETE_COURSE_CHAPTER = gql`
  mutation($id: Int!) {
    deleteChapter(courseChapterId: $id) {
      errors {
        errorCode,
        errorMessage,
        propertyName,
        severity
      },
      isValid
    }
  }
`

export const DELETE_COURSE_CHAPTER_PAGE = gql`
  mutation($id: Int!) {
    deletePage(courseChapterPageId: $id) {
      errors {
        errorCode,
        errorMessage,
        propertyName,
        severity
      },
      isValid
    }
  }
`

export const DELETE_COURSE_ALTERNATIVE = gql`
  mutation($id: Int!) {
    deleteCourseAlternative(id: $id) {
      errors {
        errorCode,
        errorMessage,
        propertyName,
        severity
      },
      isValid
    }
  }
`

export const REMOVE_RESOURCES_FOR_COURSE = gql`
  mutation ($courseId: Int!, $resourceId: Int!) {
    removeResourceFromCourse(courseId: $courseId, resourceId: $resourceId)
  }
`