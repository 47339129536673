import React from "react"
import CKEditor from "@ckeditor/ckeditor5-react"
import { EditorBalloonBlock } from "@nhi/ckeditor5"
import { makeStyles } from "@material-ui/core"
//import CKEditorInspector from "@ckeditor/ckeditor5-inspector"
import clsx from "clsx"

const editorConfigurations = {
  allowedContent: true,
  typing: {
    transformations: {
      remove: [
        "quotesPrimary",
        "quotesSecondary",
        "quotesPrimaryEnGb",
        "quotesSecondaryEnGb",
        "quotesPrimaryPl",
        "quotesSecondaryPl",
      ],
    },
  },
}

const useStyles = makeStyles(({ palette }) => ({
  border: {
    border: "1px solid",
    borderColor: palette.grey[400],
    borderRadius: 4,
    width: "100%",
  },
  editor: {
    "& ul": {
      listStyle: "inherit",
      paddingLeft: "40px",
    },
  },
}))

const BaloonBlock = (props) => {
  const classes = useStyles()

  return (
    <div className={clsx(props.border && classes.border, classes.editor)}>
      <CKEditor
        config={editorConfigurations}
        className={classes.editor}
        editor={EditorBalloonBlock}
        data={props.data}
        key={`${props.name}${props.id}`}
        name={props.name}
        onInit={(editor) => {
          // activate development editor
          // CKEditorInspector.attach(editor)
          // You can store the "editor" and use when it is needed.
          if (props.focused) {
            editor.editing.view.focus()
          }
        }}
        // onChange = { ( event, editor ) => {
        // } }
        onBlur={(event, editor) => {
          const data = editor.getData()
          if (data !== props.data) {
            props.getDataCallback(data, props.name)
          }
        }}
        // onFocus = { ( event, editor ) => {
        // } }
        disabled={props.disabled}
      />
    </div>
  )
}

export default BaloonBlock
