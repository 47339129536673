import React, { useEffect } from "react"
import { useMutation, useQuery } from "@apollo/client"
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  LinearProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
} from "@material-ui/core"
import AddIcon from "@material-ui/icons/Add"
import { useSnackbar } from "notistack"
import { makeStyles } from "@material-ui/core/styles"
import GoToNew from "../../utils/GoToNew"
import { CREATE_COURSE, GET_CATEGORIES } from "../../gql"

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: "300px",
  },
  dialogContent: {
    width: "100%",
  },
  formControl: {
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

const CreateCourseButton = ({ type }) => {
  const [selectedNameCourse, setSelectedNameCourse] = React.useState("")
  const [categories, setCategories] = React.useState([])
  const [chosenCategory, setChosenCategory] = React.useState("")
  const [openDialogCourse, setOpenDialogCourse] = React.useState(false)
  const [openCategoryDropdown, setOpenCategoryDropdown] = React.useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()

  const { data, networkStatus } = useQuery(GET_CATEGORIES, {
    variables: {
      type: type,
    },
    notifyOnNetworkStatusChange: true,
  })

  const createMappedCategories = (categories) => {
    const mappedCategories = []
    categories.forEach((parent) => {
      parent.subCategories.forEach((child) => {
        mappedCategories.push({
          id: child.id,
          name: `${parent.name}/${child.name}`,
        })
      })
    })
    return mappedCategories
  }

  useEffect(() => {
    if (networkStatus === 7) {
      setCategories(createMappedCategories(data.categoriesByCourseType))
    }
  }, [data, networkStatus])

  const [createCourse, { loading: mutationLoading }] = useMutation(
    CREATE_COURSE,
    {
      variables: {
        name: selectedNameCourse,
        type: type,
        categoryId: chosenCategory,
      },
      onCompleted(data) {
        if (data) {
          console.log("Add course")
          enqueueSnackbar("Add course successfully", { variant: "success" })
          GoToNew(data.createCourse)
        } else {
          console.log("not added")
          enqueueSnackbar("Add course failed", { variant: "error" })
        }
      },
    }
  )

  const handleOpenDropdown = () => {
    setOpenCategoryDropdown(true)
  }
  const handleCloseDropdown = () => {
    setOpenCategoryDropdown(false)
  }

  const handleOpenDialogCourse = () => {
    setOpenDialogCourse(true)
  }

  const handleCloseDialogCourse = () => {
    setOpenDialogCourse(false)
  }

  const handleChangeNameCourse = (event) => {
    setSelectedNameCourse(event.target.value)
  }
  const handleChangeCategory = (event) => {
    setChosenCategory(event.target.value)
  }

  const errorNameCourse =
    selectedNameCourse === "" || selectedNameCourse.length < 3

  return (
    <>
      <Button
        color="primary"
        variant="outlined"
        startIcon={mutationLoading ? <CircularProgress /> : <AddIcon />}
        onClick={handleOpenDialogCourse}
      >
        Add new course
      </Button>
      <Dialog
        open={openDialogCourse}
        onClose={handleCloseDialogCourse}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        {mutationLoading && <LinearProgress />}
        <DialogTitle id="alert-dialog-title">Add a course</DialogTitle>
        <Grid container>
          <DialogContent className={classes.dialogContent}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                margin="dense"
                id="name"
                label="Name"
                type="text"
                error={errorNameCourse}
                onChange={handleChangeNameCourse}
                helperText={
                  errorNameCourse
                    ? "You must set a name and it must be 3 characters or longer"
                    : " "
                }
                value={selectedNameCourse}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl className={classes.formControl}>
                <InputLabel> Category </InputLabel>

                <Select
                  labelId="category-open-select-label"
                  open={openCategoryDropdown}
                  onOpen={handleOpenDropdown}
                  onClose={handleCloseDropdown}
                  onChange={handleChangeCategory}
                  className={classes.selectEmpty}
                  value={chosenCategory}
                  autoWidth={true}
                >
                  {categories.map((category) => (
                    <MenuItem
                      key={"category" + category.id}
                      value={category.id}
                    >
                      {" "}
                      {category.name}{" "}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </DialogContent>
          <Grid container spacine={3} justifyContent="flex-end">
            <Grid item>
              <DialogActions>
                <Button onClick={handleCloseDialogCourse} color="primary">
                  Cancel
                </Button>
                <Button
                  disabled={
                    errorNameCourse ||
                    (mutationLoading && true) ||
                    chosenCategory === ""
                  }
                  onClick={createCourse}
                  color="primary"
                >
                  Add
                </Button>
              </DialogActions>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </>
  )
}

export default CreateCourseButton
