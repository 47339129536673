import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import AuthorizedApolloProvider from "./utils/apolloprovider"
import history from "./utils/history"
import { Auth0Provider } from "./utils/oauth"
import { SnackbarProvider } from "notistack"
import { ThemeProvider } from "@material-ui/core/styles"
import { theme } from "./theme.js"


const onRedirectCallback = appState => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  )
}


ReactDOM.render(
  <SnackbarProvider maxSnack={3}>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      client_id={process.env.REACT_APP_AUTH0_CLIENT_ID}
      redirect_uri={`${window.location.origin}`}
      scope={process.env.REACT_APP_AUTH0_SCOPE}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens={true}
      authorizeTimeoutInSeconds={15}
      role_uri={process.env.REACT_APP_AUTH0_ROLE_URI}
    >
      <AuthorizedApolloProvider>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </AuthorizedApolloProvider>
    </Auth0Provider>
  </SnackbarProvider>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()