import React, { useContext, useEffect, useState } from "react"
import { Redirect } from "react-router-dom"
import { useQuery } from "@apollo/client"
import {
  LinearProgress,
  TextField,
  Switch,
  Typography,
  Grid,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core"

import { usePageState } from "./usePageState.jsx"
import { urlContext } from "../../hooks/urlHooks"
import { GET_COURSE_PAGE_BY_NUMBER } from "../../gql"
import usePageHandlers from "./useHandlers"
import { errorSnackbars } from "../../utils/ErrorResponseHandler"
import GeneralSkeleton from "../Skeletons/GeneralSkeleton"
import { Tabs, TabPanel } from "../Tabs"
import FormHeader from "../FormHeader"
import FormSection from "../FormSection"
import BaloonBlock from "../Editors/BaloonBlock"
import AlternativeList from "../CourseAlternative/AlternativeList"
import History from "../History/History"
import FabBlock from "../Fab/FabBlock"
import HISTORY_TYPES from "../History/HISTORY_TYPES"

const CoursePageView = () => {
  const [tab, setTab] = useState(0)
  const { state: urlState, dispatch: urlDispatch } = useContext(urlContext)

  const {
    courseId,
    chapterNumber,
    pageNumber,
    pageErrors,
    changeFields,
    pageData,
    muationLoading,
    errorMutation,
    fieldRules,
    updateData,
    setBlockData,
    notifyChangeAlternatives,
    saveHandler,
    resetFromCloud,
  } = usePageState()

  const {
    excludeHandler,
    repeatHandler,
    pageTypeHandler,
    numberHandler,
    orderAlternativesRandomHandler,
  } = usePageHandlers(setBlockData)

  const errorSnackbarsComponent = errorSnackbars([...pageErrors])
  const [saving, setSaving] = useState(false)

  const { loading, data, error, refetch, networkStatus } = useQuery(
    GET_COURSE_PAGE_BY_NUMBER,
    {
      variables: {
        courseId: courseId,
        chapterNumber: chapterNumber,
        pageNumber: pageNumber,
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
    }
  )

  useEffect(() => {
    if (networkStatus === 1) {
      //do nothing
    } else if (networkStatus === 7) {
      // This means that the data is ready
      const pageData = data?.pageByCourseIdChapterNumberPageNumber
      updateData(data)
      if (pageData) {
        urlDispatch({
          type: "singlePage",
          pageid: pageData.id,
          pagenumber: pageData.number,
          pagetype: pageData.pageType,
          orderAlternativesRandom: pageData.orderAlternativesRandom,
        })
      }
      setSaving(false)
    }
  }, [data, networkStatus, updateData, urlDispatch])

  if (loading || pageData === undefined || Object.keys(pageData).length === 0) {
    return (
      <>
        <LinearProgress />
        <GeneralSkeleton />
      </>
    )
  }

  if (error) {
    return <Redirect to="/error"></Redirect>
  }

  const unsavedChanges = !(
    Object.keys(changeFields).length === 0 &&
    changeFields.constructor === Object
  )
  const topGridWidth =
    pageData.pageType === "MULTIPLE" ||
    pageData.pageType === "SINGLE" ||
    pageData.pageType === "REPEAT"
      ? 3
      : 4

  const switchOrderAlternativesRandom = (
    <FormControlLabel
      labelPlacement="start"
      control={
        <Switch
          name={"orderAlternativesRandom"}
          onChange={orderAlternativesRandomHandler}
          checked={pageData.orderAlternativesRandom}
          disabled={fieldRules.orderAlternativesRandom}
          color="primary"
        />
      }
      label="Order Alternatives Random"
    />
  )

  return pageData.pageType === "CHAPTERSUMMARY" ? (
    <>THIS PAGE IS NOT EDITABLE</>
  ) : (
    <>
      <Tabs tabLabels={["Content", "History"]} tab={tab} setTab={setTab} />

      <FormHeader
        header={`Page type: ${pageData.pageType}`}
        unsavedChanges={unsavedChanges}
        subHeadings={[
          { label: "By: ", value: pageData?.createdBy },
          { label: "Max score: ", value: pageData?.maximumScore },
        ]}
      />

      {errorSnackbarsComponent}

      <TabPanel value={tab} index={0}>
        <FormSection heading="">
          <Grid item xs={topGridWidth}>
            <TextField
              type="number"
              label="Number"
              name={"number"}
              value={pageData?.number || 0}
              onChange={numberHandler}
              disabled={fieldRules?.number}
              variant="outlined"
              fullWidth
            />
          </Grid>

          <Grid item xs={topGridWidth}>
            <TextField
              type="number"
              label="Repeat"
              name={"repeat"}
              value={pageData?.repeat ?? ""}
              onChange={repeatHandler}
              disabled={fieldRules?.repeat || pageData?.pageType !== "REPEAT"}
              variant="outlined"
              fullWidth
            ></TextField>
          </Grid>
          {pageData.pageType === "MULTIPLE" ||
          pageData.pageType === "SINGLE" ||
          pageData.pageType === "REPEAT" ? (
            <Grid item xs={topGridWidth}>
              <FormControl variant="outlined">
                <InputLabel id="pagetype-input-label">Page Type</InputLabel>
                <Select
                  labelId="pageType "
                  id="pageType"
                  value={pageData.pageType}
                  onChange={pageTypeHandler}
                  name="pageType"
                  label="Page Type"
                  disabled={fieldRules?.pageType}
                >
                  <MenuItem value={"SINGLE"}>Single</MenuItem>
                  <MenuItem value={"MULTIPLE"}>Multiple</MenuItem>
                  <MenuItem value={"REPEAT"}>Repeat</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          ) : null}

          <Grid item xs={topGridWidth}>
            <FormControlLabel
              control={
                <Switch
                  name={"excludeFromTest"}
                  checked={pageData?.excludeFromTest ?? false}
                  onChange={excludeHandler}
                  disabled={fieldRules?.excludeFromTest}
                  color="primary"
                ></Switch>
              }
              label="Exclude from test chapters"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" gutterBottom>
              Content
            </Typography>
            <BaloonBlock
              border
              key={"contentPage" + pageData.id}
              name={"content"}
              data={pageData.content}
              getDataCallback={setBlockData}
              disabled={fieldRules?.content}
            ></BaloonBlock>
          </Grid>

          {pageData.pageType !== "INFORMATION" && (
            <Grid item xs={12}>
              <Typography variant="caption" gutterBottom>
                Question
              </Typography>
              <BaloonBlock
                border
                key={"questionPage" + pageData.id}
                name={"question"}
                data={pageData.question}
                getDataCallback={setBlockData}
                disabled={fieldRules?.question}
              ></BaloonBlock>
            </Grid>
          )}
          {!(pageData.pageType === "INFORMATION") && (
            <Grid item xs={12}>
              <Typography variant="caption" gutterBottom>
                Response
              </Typography>
              <BaloonBlock
                border
                key={"responsePage" + pageData.id}
                name={"response"}
                data={pageData.response}
                getDataCallback={setBlockData}
                disabled={fieldRules?.response}
              ></BaloonBlock>
            </Grid>
          )}
        </FormSection>

        {!(
          pageData.pageType === "INFORMATION" ||
          pageData.pageType === "FREETEXT"
        ) && (
          <FormSection
            heading="Alternatives"
            switchProp={switchOrderAlternativesRandom}
          >
            <Grid item xs={12}>
              <AlternativeList
                saving={saving}
                setBlockData={setBlockData}
                notifyChange={notifyChangeAlternatives}
                alternativeData={pageData.alternatives}
                disablePoints={false}
                courseId={courseId}
                chapterNumber={chapterNumber}
                pageNumber={pageNumber}
                orderAlternativesRandom={pageData.orderAlternativesRandom}
              />
            </Grid>
          </FormSection>
        )}
      </TabPanel>

      <TabPanel value={tab} index={1}>
        <History
          key={parseInt(pageData.id)}
          id={parseInt(pageData.id)}
          open={true}
          type={HISTORY_TYPES.COURSEPAGE}
          refetch={refetch}
        />
      </TabPanel>

      <FabBlock
        resetCallback={() => resetFromCloud(urlState, refetch)}
        saveCallback={() => {
          setSaving(true)
          saveHandler(urlState, refetch)
        }}
        unsavedChanges={unsavedChanges}
        saveLoading={muationLoading}
        saveError={errorMutation}
        id={pageData.id}
        type={"PAGE"}
      ></FabBlock>
    </>
  )
}

export default CoursePageView
