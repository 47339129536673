import React from "react"
import "./App.css"
import NavBar from "./components/NavBar"
import {
  Router,
  Switch,
  Route
} from "react-router-dom"
import CourseOverviewPage from "./pages/CourseOverviewPage"
import history from "./utils/history"
import PrivateRoute from "./components/PrivatePage"
import CourseTree from "./components/CourseTree"
import ResourceSidebar from "./components/ResourceSidebar"
import { SkeletonEditLayout } from "./components/Skeletons"
import EditRoutingComponent from "./pages/EditRoutingComponent"
import ErrorPage from "./pages/ErrorPage"
import { urlContext, useUrlParams } from "./hooks/urlHooks"
import ScrollToTop from "./utils/ScrollToTop"
import { CourseStateProvider } from "./contexts"

export const editPaths = [
  "/course/:courseid/chapter/:chapternumber/page/:pagenumber",
  "/course/:courseid/chapter/:chapternumber",
  "/course/:courseid",
]

export const physicianPaths = [
  "/",
  "/physicians",
]

function App() {
  const urlParams = useUrlParams()
  return (
    <>
      <Router history={history}>
        <ScrollToTop />
        <header>
          <NavBar />
        </header>
        <urlContext.Provider value={urlParams}>
          <CourseStateProvider>
            <Switch>
              <PrivateRoute exact path={physicianPaths} component={CourseOverviewPage} type="PHYSICIANS" />
              <PrivateRoute path="/healthprofessionals" component={CourseOverviewPage} type="HEALTHPROFESSIONALS" />
              <PrivateRoute path="/generalpublic" component={CourseOverviewPage} type="GENERALPUBLIC" />
              <PrivateRoute path="/course-tree/course/:courseid" component={CourseTree} />
              <PrivateRoute path="/resource-sidebar" component={ResourceSidebar} />
              <PrivateRoute path={editPaths} component={EditRoutingComponent} />
              <Route path="/skeleton" component={SkeletonEditLayout} />
              <Route path="/error" component={ErrorPage} />
            </Switch>
          </CourseStateProvider>
        </urlContext.Provider>
      </Router>
    </>
  )
}

export default App