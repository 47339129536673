import { makeStyles } from "@material-ui/core"

const stylesCourseInformation = makeStyles(({ spacing }) => ({
  titleContainer: {
    margin: spacing(2, 0),
  },
  subTitle: {
    marginLeft: spacing(2),
  },
}))

const stylesCourseSection = makeStyles(({ spacing }) => ({
  paper: {
    marginBottom: spacing(2),
    padding: spacing(3),
  },
}))

const stylesBlockSection = makeStyles(() => ( {
  block: {
    width: "100%",
  },
}))

export { stylesCourseInformation, stylesCourseSection, stylesBlockSection }