import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import DialogTitle from "@material-ui/core/DialogTitle"
import Dialog from "@material-ui/core/Dialog"
import diff from "node-htmldiff"
import VisibilityIcon from "@material-ui/icons/Visibility"
import { Tooltip } from "@material-ui/core"
import { green, red } from "@material-ui/core/colors"

const useStyles = makeStyles(() => ({
  content: {
    padding: "20px",
    "& ins": {
      color: green[600],
    },
    "& del": {
      color: red[600],
    },
  },
  icon: {
    marginLeft: "10px",
    marginTop: "15px",
  },
}))


export default function DiffDialog({ before, after, title, align }) {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [state] = React.useState(diff(before, after))

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Tooltip title="Visualize changes">
        <VisibilityIcon className={!align && classes.icon} onClick={handleClickOpen}></VisibilityIcon>
      </Tooltip>
      <Dialog onClose={handleClose} aria-labelledby="visualized-changes" open={open} maxWidth={"lg"}>
        <DialogTitle id="visualized-changes-title">{title}</DialogTitle>
        <div className={classes.content} dangerouslySetInnerHTML={{ __html: state }}></div>
      </Dialog>
    </>
  )
}