import { useState, useEffect } from "react"
import { saveToLocalStorage, getFromLocalStorage, deleteFromLocalStorage } from "../utils/localstorage"
import { LOCAL_STORAGE_KEY } from "../utils/localstorage"


const useLocalStorageAlternatives = (id, incData) => {
  const storageData = getFromLocalStorage(LOCAL_STORAGE_KEY.alternative, id) || { data: { text: null, response: null, points: null, unsaved: false } }
  const text = storageData.data.text
  const points = storageData.data.points
  const response = storageData.data.response
  const [unsavedChanges, setUnsavedChanges] = useState(() => {
    if(response) {
      return true
    } else if (points) {
      return true
    } else if (text) {
      return true
    }
    return false
  })
  const [changedText, setChangedText] = useState(text)
  const [changedResponse, setChangedResponse] = useState(response)
  const [changedPoints, setChangedPoints] = useState(points)
  const [data, setData] = useState(storageData.data.text === null ? incData : storageData.data)

  useEffect(
    () => {
      if(unsavedChanges) {
        saveToLocalStorage(LOCAL_STORAGE_KEY.alternative, id, data)
      } else {
        setData(incData)
        deleteFromLocalStorage(LOCAL_STORAGE_KEY.alternative, id)
        setChangedText(null)
        setChangedResponse(null)
        setChangedPoints(null)
        setUnsavedChanges(false)
      }
    }, [unsavedChanges, id, data, incData])

  useEffect(() => {
    setData((tempdata) => ({ ...tempdata,
      text: changedText !== tempdata.text ? changedText !== null ? changedText : tempdata.text : tempdata.text,
    }))
  }, [changedText])

  useEffect( () => {
    setData((tempdata) => ({ ...tempdata,
      response: changedResponse !== tempdata.response ? changedResponse !== null ? changedResponse : tempdata.response: tempdata.response,
    }))
  }, [changedResponse])

  useEffect( () => {
    setData((tempdata) => ({ ...tempdata,
      points: changedPoints !== tempdata.points ? changedPoints !== null ? changedPoints : tempdata.points : tempdata.points,
    }))
  }, [changedPoints])


  return { data, setData, unsavedChanges, setUnsavedChanges, changedText, setChangedText, changedResponse, setChangedResponse, setChangedPoints, changedPoints }

}

export { useLocalStorageAlternatives }
