import React from "react"
import { useMutation } from "@apollo/client"
import { CircularProgress, Tooltip } from "@material-ui/core"
import { useSnackbar } from "notistack"
import { CREATE_TEST_CHAPTERS } from "../../gql"
import { InfoOutlined } from "@material-ui/icons"
import Fab from "@material-ui/core/Fab"
import { generateStyleFab } from "./Styles"


const GenTestChapterFab = (props) => {
  const { courseId } = props
  const { enqueueSnackbar } = useSnackbar()
  const [clicked, setClicked] = React.useState(false)
  const classes = generateStyleFab()

  const handleClick = () => {
    setClicked(true)
    generateTestChapters()
  }

  const [
    generateTestChapters,
    { loading: mutationLoading, error: mutationError },
  ] = useMutation(CREATE_TEST_CHAPTERS, {
    variables: { courseId: courseId },
    onCompleted(data) {
      if (data?.generateTestChaptersForCourse.isValid) {
        console.log("Generated test chapters for course")
        enqueueSnackbar("Generated pre/post test chapters successfully", { variant: "success" })
      } else {
        console.log("failed to generate pre/post chapters")
        data.generateTestChaptersForCourse.courseValidation.errors.forEach(error => {
          enqueueSnackbar(error.errorMessage, { variant: "error" })
        })
        enqueueSnackbar("Failed to generate pre/post test chapters", { variant: "error" })
      }
    },
  })

  return (
    <Tooltip title="Generate pre/post chapters" aria-label="Generate pre/post chapters">
      <Fab
        className={classes.warning}
        color={!mutationError ? "primary" : "secondary"}
        disabled={(clicked || mutationError || mutationLoading)}
        onClick={handleClick}
      >
        {!mutationLoading ? <InfoOutlined /> : <CircularProgress />}
      </Fab>
    </Tooltip>
  )
}

export default GenTestChapterFab