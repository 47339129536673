import React from "react"
import Fab from "@material-ui/core/Fab"
import { Tooltip } from "@material-ui/core"
import { RotateLeft as RotateLeftIcon } from "@material-ui/icons"
import { stylesResetFab } from "./Styles"

function ResetFab({ resetCallback, text }) {
  const classes=stylesResetFab()
  return (
    <Tooltip title={text} aria-label="save">
      <Fab className={classes.fab} color="primary" aria-label="edit" onClick={resetCallback}>
        <RotateLeftIcon />
      </Fab>
    </Tooltip>
  )
}

export default React.memo(ResetFab)


