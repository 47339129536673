import React from "react"
import { useQuery } from "@apollo/client"

import { GET_COURSES_BY_GUID }from "./../../gql/get"
import { LinearProgress } from "@material-ui/core"
import SkeletonOverviewTable from "./../Skeletons/SkeletonOverviewTable"
import CourseOverviewBaseTable from "../CourseOverviewTable/CourseOverviewBaseTable"
import CloneFromOther from "./CloneFromOther"


const VersionsOverview = ( { guid, type, id, courseCategory }) => {
  const { data, loading, error, refetch } = useQuery(GET_COURSES_BY_GUID,
    { variables: { guid: guid } ,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
    }
  )
  if(loading) {
    return (<><LinearProgress /><SkeletonOverviewTable /> </>)
  }
  if(error) {
    return <div>An error happened when fetching the data</div>
  }

  return (
    <>
      <CloneFromOther type={type} id={id} courseCategory={courseCategory} />

      <CourseOverviewBaseTable title={""} refetch={refetch} data={data.coursesByGuid} showCreateButton={false} />
    </>
  )
}

export default VersionsOverview