import React from "react"
import CourseOverviewBaseTable from "./CourseOverviewBaseTable"
import useQueryCourses from "./useQueryCourses"
import { LinearProgress } from "@material-ui/core"
import SkeletonOverviewTable from "../Skeletons/SkeletonOverviewTable"
import { Redirect } from "react-router-dom"
import useTitle from "./../../hooks/useTitle"

const CourseOverviewTable = ({ type }) => {
  let title = ""
  switch (type) {
    case "PHYSICIANS":
      title = "Physician Courses"
      break
    case "HEALTHPROFESSIONALS":
      title = "Health Professional Courses"
      break
    case "GENERALPULBIC":
      title = "General public Courses"
      break
    default:
  }
  useTitle(title)
  const variables = { type: type, getLastVersionOnly: true }
  const { loading, error, data } = useQueryCourses(variables)

  if (error) {
    return <Redirect to="/error"></Redirect>
  }
  if (loading) {
    return (
      <>
        <LinearProgress />
        <SkeletonOverviewTable />{" "}
      </>
    )
  }

  return (
    <CourseOverviewBaseTable
      type={type}
      refetch={undefined}
      data={data.courses}
      title={title}
    />
  )
}

export default CourseOverviewTable
