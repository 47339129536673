const categoryType = [
  {
    value: "PHYSICIANS",
    label: "PHYSICIANS",
  },
  {
    value: "HEALTHPROFESSIONALS",
    label: "HEALTHPROFESSIONALS",
  },
  {
    value: "GENERALPUBLIC",
    label: "GENERALPUBLIC",
  },
]

const paymentPosition = [
  {
    value: "NONE",
    label: "NONE",
  },
  {
    value: "BEFORE",
    label: "BEFORE",
  },
  {
    value: "DURING",
    label: "DURING",
  },
  {
    value: "AFTER",
    label: "AFTER",
  },
]

const certificateType = [
  {
    value: "NONE",
    label: "NONE",
  },
  {
    value: "TEMPLATE",
    label: "TEMPLATE",
  },
  {
    value: "DNL",
    label: "DNL",
  },
]

const statesNotPublic = [
  {
    value: "DEVELOPMENT",
    label: "DEVELOPMENT",
  },
  {
    value: "STAGED",
    label: "STAGED",
  },
  {
    value: "DELETED",
    label: "DELETED",
  },
]

const stateChoices = statesNotPublic.concat(
  {
    value: "PUBLIC",
    label: "PUBLIC",
  }
)

export { categoryType, paymentPosition, certificateType, statesNotPublic, stateChoices }