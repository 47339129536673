import { useEffect, useState } from "react"

const useTitle = (text) => {
  const [title, setTitle] = useState( text || "Theseus Course Editor")
  useEffect(() => {
    document.title = title || "Theseus Course Editor"
  }, [title])
  return { setTitle }
}

export default useTitle