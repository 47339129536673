const HISTORY_TYPES = {
  COURSE: "COURSE",
  COURSECATEGORY: "COURSECATEGORY",
  COURSECHAPTER: "COURSECHAPTER",
  COURSEPAGE: "COURSEPAGE",
  COURSEALTERNATIVE: "COURSEALTERNATIVE",
  RESOURCE: "RESOURCE",
  TOPLEVEL: "TOPLEVEL",
}

export default HISTORY_TYPES