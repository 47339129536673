import React from "react"
import Fab from "@material-ui/core/Fab"
import SaveIcon from "@material-ui/icons/Save"
import { CircularProgress, Tooltip } from "@material-ui/core"
import { stylesSaveFab } from "./Styles"

function SaveFab({ unsavedChanges, saveCallback, loading, text }) {
  const classes = stylesSaveFab()
  return (
    <div>
      <Tooltip title={text} aria-label="save">
        <Fab
          className={unsavedChanges ? classes.warning : classes.standard}
          aria-label="edit"
          disabled={loading}
          onClick={saveCallback}
        >
          {loading ? <CircularProgress color="primary" /> : <SaveIcon />}
        </Fab>
      </Tooltip>
    </div>
  )
}

export default React.memo(SaveFab)


