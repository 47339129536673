import React from "react"

import {
  Menu,
  MenuItem,
  Tooltip,
  Button,
  LinearProgress
} from "@material-ui/core"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import { useMutation } from "@apollo/client"
import { useSnackbar } from "notistack"
import GoToNew from "../../utils/GoToNew"
import { CLONE_COURSE } from "../../gql"
import { UpdateOverviewWithNewCourseClone } from "../../utils/UpdateApolloCache"
import { useDeleteMutation } from "../../hooks"
import FileCopyIcon from "@material-ui/icons/FileCopy"
import DeleteIcon from "@material-ui/icons/Delete"
import { makeStyles } from "@material-ui/core/styles"
import AlertDeleteDialog from "../Dialogs/AlertDeleteDialog"

const useStyles = makeStyles(({ spacing }) => ({
  menuItem: {
    padding: "0px",
  },
  button: {
    padding: "10px",
    margin: "0px",
  },
  expandButton: {
    marginLeft: spacing(2),
  },
}))

const CourseMenu = ({ sessionCount, id, courseName, refetch }) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const [deleteObject, { loading: mutationLoading }] = useDeleteMutation("COURSE")
  const [deleteOpen, setDeleteOpen] = React.useState(false)
  const deleteTitle = sessionCount ? `This course can not be deleted because it has ${sessionCount} active sessions` : "Delete course"
  const disableDelete = sessionCount ? true : false
  const cloneTitle = "Clone course"
  const { enqueueSnackbar } = useSnackbar()

  const variables = { id: id }
  const handleClick = (event) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (event) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  const handleShowingDialog = (state) => {
    setDeleteOpen(state)
  }

  const handleDeleteOpen = (event) => {
    event.stopPropagation()
    setDeleteOpen(true)
  }

  const handleCloneCourse = (event) => {
    event.stopPropagation()
    cloneCourse()
  }


  const [
    cloneCourse,
    { loading },
  ] = useMutation(CLONE_COURSE, {
    variables: { id: id },
    update: UpdateOverviewWithNewCourseClone,
    onCompleted(data) {
      if (data) {
        console.log("Clone course")
        enqueueSnackbar("Clone course successfully", { variant: "success" })
        GoToNew({ id: data.cloneCourse.id })
        if(refetch) {
          refetch()
        }
      } else {
        console.log("not cloned")
        enqueueSnackbar("Clone course failed", { variant: "error" })
      }
    },
  })


  return (
    <>
      <MoreVertIcon
        onClick={handleClick}
      />
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "20ch",
            padding: "0px",
          },
        }}
      >
        {(loading || mutationLoading) && <LinearProgress />}
        <Tooltip title={cloneTitle}>
          <MenuItem className={classes.menuItem}>
            <Button className={classes.button} startIcon={<FileCopyIcon />} fullWidth onClick={handleCloneCourse} disabled={loading}>
              Clone
            </Button>
          </MenuItem>
        </Tooltip>
        <Tooltip title={deleteTitle}>
          <MenuItem className={classes.menuItem} >
            <Button className={classes.button} disabled={disableDelete} onClick={handleDeleteOpen} startIcon={< DeleteIcon />} fullWidth >
              Delete
            </Button>
          </MenuItem>
        </Tooltip>
      </Menu>
      <AlertDeleteDialog
        handleShowing={handleShowingDialog}
        open={deleteOpen}
        text={"You are about to delete " + courseName + ". This deletion sets the satus of the course to deleted and can be reversed"}
        callback={deleteObject}
        variables={variables}
      />
    </>
  )
}

export default CourseMenu