import { makeStyles } from "@material-ui/core/styles"

const drawerWidth = 500


const stylesNavBar = makeStyles ((theme) => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none",
    },
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  toolbar: {
    flexWrap: "wrap",
    height: "100%",
    [theme.breakpoints.up("lg")]: {
      paddingTop: "5px",
      paddingBottom: "25px",
    },
    [theme.breakpoints.down("md")]: {
      paddingTop: "5px",
      paddingBottom: "25px",
    },
  },
  toolbarTitle: {
    [theme.breakpoints.up("lg")]: {
      flexGrow: 1,
      alignSelf: "flex-end",
      marginLeft: "50px",
      fontWeight: 1200,
      fontSize: theme.typography.h2.fontSize,
    },
    [theme.breakpoints.down("md")]: {
      flexGrow: 1,
      alignSelf: "flex-end",
      marginLeft: "30px",
      fontWeight: 1200,
      fontSize: theme.typography.h3.fontSize,
    },
  },
  link: {
    [theme.breakpoints.up("lg")]: {
      margin: theme.spacing(0, 1.5),
      alignSelf: "flex-end",
      fontSize: theme.typography.body1.fontSize,
    },
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(0, 1),
      alignSelf: "flex-end",
      fontSize: theme.typography.body2.fontSize,

    },

  },
  login: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  avatar: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(-1),
    alignSelf: "flex-end",
  },
  appBar: {
    display: "flex",
    height: "114px",
    borderBottom: `1px solid ${theme.palette.divider}`,
    marginBottom: "15px",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
  logo: {
    [theme.breakpoints.up("lg")]: {
      alignSelf: "flex-end",
      width: "200px",
    },
    [theme.breakpoints.down("md")]: {
      alignSelf: "flex-end",
      width: "150px",
    },
  },
}))

export { stylesNavBar }