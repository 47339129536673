import React, { useEffect, useState } from "react"
import { Redirect, useParams } from "react-router-dom"

import { List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Popover,
  Divider,
  LinearProgress,
  IconButton,
  Typography,
  makeStyles
} from "@material-ui/core"
import { ExpandMore as ExpandMoreIcon, MoreVert as MoreVertIcon } from "@material-ui/icons"
import { useQuery, useMutation } from "@apollo/client"
import { useSnackbar } from "notistack"
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state"

import AddResourceBox from "./AddResourceBox"
import UpdateResource from "./UpdateResource"
import { GET_RESOURCES_FOR_COURSE, REMOVE_RESOURCES_FOR_COURSE } from "../../gql"
import RemoveResourceFromCourseButton from "../Buttons/RemoveResourceFromCourseButton"


const useStyles = makeStyles((theme) => ({
  resourceDropdown: {
    marginBottom: "10px",
  },
  resourceContent: {
    width: "100%",
  },
  showClickable: {
    cursor: "pointer",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}))


const ResourceRowElement = ( { resource, refetch, courseId }) => {
  const [showVertIcon, setShowVertIcon] = useState(false)
  const classes = useStyles()

  const setShow = () => {
    setShowVertIcon(true)
  }
  const setNotShow = () => {
    setShowVertIcon(false)
  }


  return (
    <div
      onMouseEnter={setShow}
      onMouseLeave={setNotShow}
    >
      <ListItem onClick={() => { window.open(resource.url, "_black", "noopener,noreferrer")}}
      >
        <Tooltip title={resource.url}>
          <ListItemText className={classes.showClickable} primary={resource.name}></ListItemText>
        </Tooltip>
        <ListItemSecondaryAction>
          <PopupState variant="popover" popupId={resource.id+""}>
            {PopupState => (

              <>
                {showVertIcon && <IconButton {...bindTrigger(PopupState)}><MoreVertIcon ></MoreVertIcon> </IconButton>}
                <Popover
                  {...bindPopover(PopupState)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <div style={{ margin: "10px" }}>
                    <UpdateResource resource={resource} refetch={refetch} closePopup={PopupState.close}></UpdateResource>
                    <RemoveResourceFromCourseButton refetch={refetch} courseid={courseId} id={resource.id} closePopup={PopupState.close} type="RESOURCE" ></RemoveResourceFromCourseButton>
                  </div>
                </Popover>
              </>
            )}
          </PopupState>
        </ListItemSecondaryAction>
      </ListItem>
    </div>
  )
}

// Builds the rows for the list
const renderRow = (resources, callback, refetch, courseId) => {
  return resources.map((resource) => {
    return (
      <ResourceRowElement key={"res"+resource.id} resource={resource} callback={callback} refetch={refetch} courseId={courseId} />
    )
  })
}

const ResourceSidebar = () => {

  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  async function refetchFromQuery() {
    const { data } = await refetch()
    setResourceData(data.resourcesByCourseId)
  }
  const courseId = parseInt(useParams().courseid)
  const variables = { courseId: courseId }
  const [resourceData, setResourceData] = React.useState([])
  const [removeResource] = useMutation(REMOVE_RESOURCES_FOR_COURSE,
    {
      onCompleted(data) {
        if (data) {
          enqueueSnackbar("Remove resource successfully", { variant: "success" })
          refetchFromQuery()
        } else {
          enqueueSnackbar("Remove resource failed", { variant: "error" })
        }
      },
    })


  const { error, data, loading, refetch } = useQuery(GET_RESOURCES_FOR_COURSE, {
    variables,
  }
  )

  useEffect( () => {
    if(data){
      setResourceData(data.resourcesByCourseId)
    }
  }, [data])

  if(error) {
    return <Redirect to="/error"></Redirect>
  }
  return(
    <div className={classes.resourceDropdown}>
      {loading && <LinearProgress/>}
      <Accordion TransitionProps={{ unmountOnExit: true }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Resources</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.resourceContent}>
            <List>
              <ListItem>
                <ListItemText primary="Add resource" />
                <ListItemSecondaryAction>
                  <AddResourceBox refetch={refetchFromQuery} courseId={courseId}></AddResourceBox>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider orientation="horizontal"></Divider>
              {renderRow(resourceData, removeResource, refetch, courseId)}
            </List>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default ResourceSidebar