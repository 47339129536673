import React from "react"
import { Autocomplete } from "@material-ui/lab"
import { makeStyles } from "@material-ui/core/styles"
import {
  TextField,
  LinearProgress,
  Typography,
  Grid,
  Button,
} from "@material-ui/core"
import useQueryCourses from "./../CourseOverviewTable/useQueryCourses"
import VersionChip from "./../CourseOverviewTable/VersionChip"
import { createParsedDate } from "./../CourseOverviewTable/CourseOverviewBaseTable"
import FormSection from "./../FormSection"
import { useMutation } from "@apollo/client"
import { useSnackbar } from "notistack"
import GoToNew from "./../../utils/GoToNew"
import { CLONE_COURSE_FROM_OTHER } from "./../../gql/clone"
import { UpdateOverviewWithNewCourseCloneFromOther } from "../../utils/UpdateApolloCache"

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
})

const CloneCourseFromOtherButton = ({ courseId, courseFromId, className }) => {
  const { enqueueSnackbar } = useSnackbar()
  let button

  const [cloneCourse, { loading: mutationLoading, error: mutationError }] =
    useMutation(CLONE_COURSE_FROM_OTHER, {
      variables: { id: courseId, cloneToId: courseFromId },
      update: UpdateOverviewWithNewCourseCloneFromOther,
      onCompleted(data) {
        if (data) {
          console.log("Clone course")
          enqueueSnackbar("Clone course successfully", { variant: "success" })
          GoToNew({ id: data.cloneCourseFromOther.id })
        } else {
          console.log("not cloned")
          enqueueSnackbar("Clone course failed", { variant: "error" })
        }
      },
    })

  if (mutationLoading) {
    button = (
      <Button
        className={className}
        disabled={true}
        variant="contained"
        onClick={cloneCourse}
      >
        Cloning
      </Button>
    )
  } else if (mutationError) {
    button = (
      <Button
        className={className}
        disabled={true}
        variant="contained"
        color="secondary"
        onClick={cloneCourse}
      >
        Error
      </Button>
    )
  } else {
    button = (
      <Button
        fullWidth
        className={className}
        variant="contained"
        onClick={cloneCourse}
      >
        Clone
      </Button>
    )
  }

  return button
}

const CloneFromOther = ({ type, id, courseCategory }) => {
  const classes = useStyles()

  const [value, setValue] = React.useState()

  const variables = { type: type, getLastVersionOnly: true }
  const { loading, data } = useQueryCourses(variables)
  const category =
    courseCategory.parent !== null
      ? `${courseCategory.parent.name} / ${courseCategory.name}`
      : courseCategory.name
  // Search for existing courses
  // Name - Category - Version
  // List information of chosen course
  if (loading) {
    return <LinearProgress />
  }

  return (
    <FormSection
      className={classes.block}
      heading={"Clone from another course"}
    >
      <Grid item>
        <Typography>
          The cloned course will have the category: {category}.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          fullWidth
          id="course-clone-select"
          options={data?.courses}
          classes={{
            option: classes.option,
          }}
          autoHighlight
          onChange={(event, newValue) => {
            setValue(newValue)
          }}
          getOptionLabel={(option) => {
            const courseCat =
              option.courseCategory.parent !== null
                ? `${option.courseCategory.parent.name} / ${option.courseCategory.name}`
                : option.courseCategory.name

            return `${option.name} - ${courseCat}`
          }}
          renderOption={(option) => (
            <React.Fragment>
              <VersionChip label={option.version} state={option.state} />
              <Typography>
                {" "}
                {option.name} -{" "}
                {option.courseCategory.parent !== null
                  ? `${option.courseCategory.parent.name} / ${option.courseCategory.name}`
                  : option.courseCategory.name}
              </Typography>
            </React.Fragment>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Choose a course to clone from"
              variant="outlined"
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password", // disable autocomplete and autofill
              }}
            />
          )}
        />
      </Grid>
      {value ? (
        <>
          <Grid item sm={6}>
            <TextField
              variant="outlined"
              label="Name"
              name={"Name"}
              value={value.name ?? ""}
              disabled
              fullWidth
            >
              {value.name}
            </TextField>
          </Grid>
          <Grid item sm={6}>
            <TextField
              variant="outlined"
              label="Category"
              name={"Category"}
              value={
                value.courseCategory.parent !== null
                  ? `${value.courseCategory.parent.name} / ${value.courseCategory.name}`
                  : value.courseCategory.name
              }
              disabled
              fullWidth
            >
              {value.courseCategory.parent !== null
                ? `${value.courseCategory.parent.name} / ${value.courseCategory.name}`
                : value.courseCategory.name}
            </TextField>
          </Grid>
          <Grid item sm={6}>
            <TextField
              variant="outlined"
              label="Last modified"
              name={"Last modified"}
              value={createParsedDate(value.lastModified) ?? ""}
              disabled
              fullWidth
            >
              {createParsedDate(value.lastModified)}
            </TextField>
          </Grid>
          <Grid item sm={6}>
            <TextField
              variant="outlined"
              label="Version"
              name={"Version"}
              value={value.version ?? ""}
              disabled
              fullWidth
            >
              {value.version}
            </TextField>
          </Grid>
          <Grid item>
            <CloneCourseFromOtherButton
              courseId={value.id}
              courseFromId={id}
            ></CloneCourseFromOtherButton>
          </Grid>
        </>
      ) : null}
    </FormSection>
  )
}

export default CloneFromOther
