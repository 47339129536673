import { useQuery } from "@apollo/client"
import { ALL_COURSES } from "../../gql"


const useQueryCourses = (variables) => {
  return useQuery(ALL_COURSES, {
    variables,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  }
  )
}

export default useQueryCourses