import React from "react"

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  TextField,
  LinearProgress,
  CircularProgress
} from "@material-ui/core"
import { SpeedDial, SpeedDialAction } from "@material-ui/lab"
import AddIcon from "@material-ui/icons/Add"
import { useParams } from "react-router-dom"
import { useMutation } from "@apollo/client"
import { useSnackbar } from "notistack"

import { useCourseStateDispatch } from "../../contexts/CourseStateContext"
import GoToNew from "../../utils/GoToNew"
import { CREATE_CHAPTER, CREATE_PAGE, CREATE_COURSE_ALTERNATIVE, GET_COURSE_TREE, GET_COURSE_PAGE_BY_NUMBER } from "../../gql"
import { urlContext } from "../../hooks/urlHooks"
import { stylesAddFab } from "./Styles"

const pageType = [
  { value: "SINGLE", label: "SINGLE" },
  { value: "MULTIPLE", label: "MULTIPLE" },
  { value: "REPEAT", label: "REPEAT" },
  { value: "FREETEXT", label: "FREETEXT" },
  { value: "INFORMATION", label: "INFORMATION" },
]


const AddFab = () => {
  const { courseid, chapternumber, pagenumber } = useParams()
  const courseId = parseInt(courseid)
  const chapterNumber = parseInt(chapternumber)
  const pageNumber = parseInt(pagenumber)
  const [open, setOpen] = React.useState(false)
  const [selectedPageType, setSelectedPageType] = React.useState("")
  const [selectedNameChapter, setSelectedNameChapter] = React.useState("")
  const [openDialogChapter, setOpenDialogChapter] = React.useState(false)
  const [openDialogPage, setOpenDialogPage] = React.useState(false)
  const { state } = React.useContext(urlContext)
  const { enqueueSnackbar } = useSnackbar()
  const classes = stylesAddFab()
  const dispatch = useCourseStateDispatch()

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleOpenDialogChapter = () => {
    setOpenDialogChapter(true)
  }

  const handleCloseDialogChapter = () => {
    setOpenDialogChapter(false)
  }

  const handleOpenDialogPage = () => {
    setOpenDialogPage(true)
  }

  const handleCloseDialogPage = () => {
    setOpenDialogPage(false)
  }

  const handleChangePageType = (event) => {
    setSelectedPageType(event.target.value)
  }

  const handleChangeChapterName = (event) => {
    setSelectedNameChapter(event.target.value)
  }

  const errorPageType = selectedPageType === ""
  const errorNameChapter = selectedNameChapter === ""

  const [createChapter, { loading: createChapterLoading }] = useMutation(CREATE_CHAPTER, {
    variables: { courseId: courseId, name: selectedNameChapter },
    refetchQueries: [{
      query:GET_COURSE_TREE,
      variables: { id: parseInt(courseid) },
    }],
    onCompleted(data) {
      if (data) {
        console.log("Create chapter")
        GoToNew(data.createChapter)
        dispatch({ type: "NOCHANGES" })
      } else {
        console.log("not created")
        enqueueSnackbar("Create chapter failed", { variant: "error" })
      }
    },
  })

  const [createPage, { loading: createPageLoading }] = useMutation(CREATE_PAGE, {
    variables: {
      courseId: courseId,
      chapternumber: chapterNumber,
      pageType: selectedPageType,
    },
    refetchQueries: [{
      query:GET_COURSE_TREE,
      variables: { id: parseInt(courseid) },
    }],
    onCompleted(data) {
      if (data) {
        console.log("Create page")
        GoToNew(data.createPage)
        dispatch({ type: "NOCHANGES" })
      } else {
        console.log("not created")
        enqueueSnackbar("Create page failed", { variant: "error" })
      }
    },
  })

  const [createAlternative, { loading: createAlernativeLoading }] = useMutation(CREATE_COURSE_ALTERNATIVE, {
    refetchQueries: [
      {
        query:GET_COURSE_PAGE_BY_NUMBER,
        variables: { courseId: courseId, chapterNumber: chapterNumber, pageNumber: pageNumber },
      },
    ],
    variables: {
      coursePageId: state.pageid,
      response: "",
      text: "",
      points: 0,
      number: 0,
    },
  })

  return (
    <>
      <SpeedDial
        ariaLabel={"Add chapter or page button"}
        icon={<AddIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        className={classes.speedDial}
      >
        <SpeedDialAction
          key={"Add chapter"}
          icon={<AddIcon />}
          tooltipTitle={"Chapter"}
          tooltipOpen
          onClick={handleOpenDialogChapter}
        />
        {chapternumber
          ? (
            <SpeedDialAction
              key={"Add page"}
              icon={<AddIcon />}
              tooltipTitle={"Page"}
              tooltipOpen
              onClick={handleOpenDialogPage}
            />
          )
          : undefined}
        { (state.pagetype === "INFORMATION" || state.pagetype === "FREETEXT" || !pagenumber)
          ? undefined
          : (
            <SpeedDialAction
              key={"Add alternative"}
              icon={createAlernativeLoading ? <CircularProgress /> :<AddIcon />}
              tooltipTitle={"Alternative"}
              tooltipOpen
              onClick={createAlternative}
            />
          )
        }
      </SpeedDial>
      <Dialog
        open={openDialogChapter}
        onClose={handleCloseDialogChapter}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {createChapterLoading && <LinearProgress />}
        <DialogTitle id="alert-dialog-title">Add a chapter</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText id="alert-dialog-description"></DialogContentText>
          <TextField
            required
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            error={errorNameChapter}
            onChange={handleChangeChapterName}
            helperText={errorNameChapter ? "You must set a name" : " "}
            value={selectedNameChapter}
          />
        </DialogContent>
        <DialogActions className={classes.dialogContent}>
          <Button onClick={handleCloseDialogChapter} color="primary">
            Cancel
          </Button>
          <Button
            disabled={errorNameChapter || createChapterLoading || createAlernativeLoading}
            onClick={createChapter}
            color="primary"
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
      {chapternumber
        ? (
          <Dialog
            open={openDialogPage}
            onClose={handleCloseDialogPage}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            {createPageLoading && <LinearProgress />}
            <DialogTitle id="alert-dialog-title">Add a page</DialogTitle>
            <DialogContent className={classes.dialogContent}>
              <DialogContentText id="alert-dialog-description"></DialogContentText>
              <TextField
                required
                margin="dense"
                id="pageType"
                label="Page type"
                select
                fullWidth
                error={errorPageType}
                onChange={handleChangePageType}
                helperText={errorPageType ? "You must select a type" : " "}
                value={selectedPageType}
              >
                {pageType.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialogPage} color="primary">
              Cancel
              </Button>
              <Button
                disabled={errorPageType || (createPageLoading && true)}
                onClick={createPage}
                color="primary"
              >
              Add
              </Button>
            </DialogActions>
          </Dialog>
        )
        : undefined}
    </>
  )
}

export default AddFab