import React from "react"
import AddFab from "./AddFab"
import SaveFab from "./SaveFab"
import DeleteFab from "./DeleteFab"
import { Tooltip } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { useCourseState } from "../../contexts"
import ResetFab from "./ResetFab"
import VersionIcon from "../Alert/VersionIcon"
import PublishFab from "./PublishFab"
import GenTestChapterFab from "./GenTestChapterFab"

const useStyles = makeStyles((theme) => ({
  fab: {
    alignSelf: "flex-end",
    height: "100%",
    margin: theme.spacing(1),
  },
  fabBlock: {
    display: "flex",
    position: "fixed",
    bottom: theme.spacing(2),
    zIndex: 99,
    pointerEvents: "none",
    right: "20px",
  },
}))


const FabBlock = ({ unsavedChanges, saveCallback, resetCallback, saveLoading, saveError, type, id }) => {
  const classes = useStyles()
  const courseState = useCourseState()
  const versionText = courseState.courseSession ? `State ${courseState.courseState}, has active sessions` : `State ${courseState.courseState}`
  return (
    courseState.courseState
      ? <div className={classes.fabBlock}>
        {
          (courseState.courseState === "STAGED" && courseState.validationPassed)
          && <>
            <div className={classes.fab}>
              <PublishFab courseId={id} />
            </div>
            <div className={classes.fab}>
              <GenTestChapterFab courseId={id} />
            </div>
          </>
        }
        {unsavedChanges
          && <div className={classes.fab}>
            <ResetFab resetCallback={resetCallback} text={"Reset all changes"} />
          </div>
        }

        {
          ((courseState.courseState === "PUBLIC" || courseState.courseState === "STAGED") && (type === "CHAPTER" || type === "PAGE")) || courseState.courseSession
            ? null
            : <div className={classes.fab}>
              <DeleteFab type={type} id={id} />
            </div>
        }
        <div className={classes.fab}>
          <SaveFab unsavedChanges={unsavedChanges} saveCallback={saveCallback} loading={saveLoading} error={saveError} text={"Save " + type.toLowerCase()} />
        </div>
        {
          courseState.courseState === "STAGED" || courseState.courseState === "PUBLIC" || courseState.courseSession
            ? null
            : <div className={classes.fab}>
              <AddFab />
            </div>
        }
        <div className={classes.fab}>
          <Tooltip title={versionText}>
            <div className={classes.stateInfo}>
              <VersionIcon state={courseState.courseState} version={courseState.courseVersion} />
            </div>
          </Tooltip>
        </div>
      </div>
      : null
  )
}


export default FabBlock
