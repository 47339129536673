import React from "react"
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"
import ErrorIcon from "@material-ui/icons/Error"
import { ListItem, ListItemIcon, ListItemText, Collapse, Tooltip } from "@material-ui/core"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import IconButton from "@material-ui/core/IconButton"
import ArrowForward from "@material-ui/icons/ArrowForward"
import { red } from "@material-ui/core/colors"

import { v4 as uuidv4 } from "uuid"
import history from "../../../utils/history"


const getErrorText = (type, data) => {
  switch(type) {
    case "alternative":
      return `/chapter/${data.chapterNumber}/page/${data.pageNumber}`
    case "chapter":
      return `/chapter/${data.chapterNumber}`
    case "page":
      return `/chapter/${data.chapterNumber}/page/${data.pageNumber}`
    case "course":
      return "course"
    default:
      break
  }
}

const redirect = (type, data, courseid) => {
  let errorText = getErrorText(type, data)
  if (errorText === "course") {
    errorText = ""
  }
  const href = "/course/" + courseid + errorText
  history.push(href)
}


const ValidationListItem = ( { type, data, courseid }) => {
  const [open, setOpen] = React.useState(false)
  const handleClick = () => {
    setOpen(!open)
  }

  let errors = null
  switch(type) {
    case "course":
      errors = data.errors
      break
    default:
      errors = data.validationResult.errors
  }

  const dataMapped = errors.map((error) => {
    return (<ListItem key={uuidv4()} >
      <ListItemText style={{ color: red[600] }} primary={error.errorMessage} />
      <ListItemSecondaryAction>
        <Tooltip title={"Go to location"}>
          <IconButton edte="end" onClick={() => { redirect(type, data, courseid) }}>
            <ArrowForward />
          </IconButton>
        </Tooltip>
      </ListItemSecondaryAction>
    </ListItem>
    )
  })

  return (
    <>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          <ErrorIcon style={ { color: red[600] }} />
        </ListItemIcon>
        <ListItemText primary={getErrorText(type, data)} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {dataMapped}
      </Collapse>
    </>
  )
}

export default ValidationListItem