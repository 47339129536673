import React from "react"
import { Button, CircularProgress, IconButton, Tooltip } from "@material-ui/core"
import { Delete as DeleteIcon } from "@material-ui/icons"
import { makeStyles } from "@material-ui/core/styles"
import AlertDeleteDialog from "../Dialogs/AlertDeleteDialog"
import { useMutation } from "@apollo/client"
import { useSnackbar } from "notistack"
import { REMOVE_RESOURCES_FOR_COURSE } from "./../../gql/delete"

const useStyles = makeStyles({
  progress: {
    marginLeft: "1rem",
  },
})

const RemoveResourceFromCourseButton = (props) => {
  const { type, courseid, id, refetch, closePopup } = props
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [open, setOpen] = React.useState(false)
  const [removeResource, { error, loading }] = useMutation(REMOVE_RESOURCES_FOR_COURSE, {
    onCompleted(data) {
      if (data) {
        refetch()
        enqueueSnackbar("Delete "+ type.toLowerCase() + " successfully", { variant: "success" })
        closePopup()
      } else {
        console.log("not deleted")
        enqueueSnackbar("Delete of " + type.toLowerCase() + " failed", { variant: "error" })
      }
    },
  })

  const handleShowingDialog = (state) => {
    setOpen(state)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  let button
  if(loading) {
    button = <CircularProgress size={20} className={classes.progress}/>
  }
  else if (error) {
    button = <Button disable="true" variant="contained" color="secondary">Error</Button>
  }
  else {
    button = <>
      <Tooltip title={"Delete " + type.toLowerCase()}>
        <IconButton edge="end" aria-label="delete" onClick={handleOpen}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <AlertDeleteDialog handleShowing={handleShowingDialog} open={open} data={props} text={"You are about to remove a "+ type.toLowerCase() +" from this course."} callback={removeResource} variables={{ resourceId: id, courseId: courseid }}></AlertDeleteDialog>
    </>
  }

  return (button)
}

export default RemoveResourceFromCourseButton