import React from "react"

const TabPanel = ({ children, value, index, ...other }) => {
  return value !== index
    ? null
    : (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {children}
      </div>
    )
}

export default TabPanel