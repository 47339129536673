import { gql } from "@apollo/client"

export const CLONE_COURSE = gql`
  mutation($id: Int!) {
    cloneCourse(courseId: $id) {
      id
      name
      guid
      version
      state
    }
  }
`


export const CLONE_COURSE_FROM_OTHER = gql`
  mutation($id: Int!, $cloneToId: Int!) {
    cloneCourseFromOther(courseId: $id, cloneToId: $cloneToId) {
      id
      name
      guid
      version
      state
    }
  }
`