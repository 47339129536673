import React from "react"
import { Tooltip, Avatar } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"


const useStyles = makeStyles((theme) => ({
  base: {
    fontSize: "13px",
    // height: "20px",
  },
  public: {
    backgroundColor: theme.palette.success.dark,
  },
  development: {
    backgroundColor: theme.palette.warning.dark,
  },
  staged: {
    backgroundColor: theme.palette.info.dark,
  },
  deleted: {
    backgroundColor: theme.palette.error.dark,
  },
  stateInfo: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
}))

const VersionIcon = ({ version, state }) => {
  const classes = useStyles()
  const versionText = `State ${state} `

  const getColor = React.useCallback((type) => {
    switch (type) {
      case "DEVELOPMENT":
        return classes.development
      case "PUBLIC":
        return classes.public
      case "STAGED":
        return classes.staged
      case "DELETED":
        return classes.deleted
      default:
    }
  }, [classes.deleted, classes.development, classes.public, classes.staged])

  const color = getColor(state)


  return (
    <Tooltip title={versionText}>
      <div className={classes.stateInfo}>
        <Avatar className={clsx(classes.base, color)}>{`v${version}`}</Avatar>
      </div>
    </Tooltip>
  )
}

export default VersionIcon