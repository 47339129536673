import React, { useState, useEffect } from "react"

import { Redirect } from "react-router-dom"
import { useQuery, useMutation } from "@apollo/client"
import { useSnackbar } from "notistack"
import { format } from "date-fns"

import BaloonBlock from "../Editors/BaloonBlock"
import { LoadingGeneralSkeleton } from "../Skeletons"
import { useParams } from "react-router-dom"
import HISTORY_TYPES from "../History/HISTORY_TYPES"
import { errorSnackbars } from "../../utils/ErrorResponseHandler"
import FabBlock from "../Fab/FabBlock"
import { COURSE_INFORMATION, UPDATE_COURSE, GET_COURSE_TREE } from "../../gql"
import {
  getFromLocalStorage,
  deleteFromLocalStorage,
  saveToLocalStorage,
  LOCAL_STORAGE_KEY,
} from "../../utils/localstorage"
import { useCourseState, useCourseStateDispatch } from "../../contexts"
import rules from "../../course-rules"
import CourseDetails from "./CourseDetails"
import History from "../History/History"
import { Tabs, TabPanel } from "../Tabs"
import FormSection from "../FormSection"
import FormHeader from "../FormHeader"
import { statesNotPublic } from "./DropdownSelects"
import createVariables from "./../../utils/createVariables"
import VersionsOverview from "./VersionsOverview"
import { stylesBlockSection } from "./Styles"
import { useCourseUnsavedDispatch } from "../../contexts/courseTree/CourseUnsavedStateContext"
import DownloadCourseButton from "../Download/DownloadCourseButton"

const stateNotPublic = [
  {
    value: "DEVELOPMENT",
    label: "DEVELOPMENT",
  },
  {
    value: "STAGED",
    label: "STAGED",
  },
  {
    value: "DELETED",
    label: "DELETED",
  },
]

const stateChoices = stateNotPublic.concat({
  value: "PUBLIC",
  label: "PUBLIC",
})

const CourseInformation = () => {
  const courseid = parseInt(useParams().courseid)
  const { enqueueSnackbar } = useSnackbar()
  const [listData, setListData] = useState()
  const [errors, setErrors] = useState([])
  const [changeFields, setChangeFields] = useState({})
  const [tab, setTab] = useState(0)
  const courseState = useCourseState()
  const dispatch = useCourseStateDispatch()
  const fieldRules = rules(courseState.courseState)?.course
  const classes = stylesBlockSection()
  const changeDispatch = useCourseUnsavedDispatch()

  const { loading, error, data, refetch, networkStatus } = useQuery(
    COURSE_INFORMATION,
    {
      variables: { CourseId: courseid },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
    }
  )
  useEffect(() => {
    if (data) {
      if (networkStatus === 7) {
        const listData = { ...data.courseById }
        const storageData = getFromLocalStorage(
          LOCAL_STORAGE_KEY.course,
          listData.id
        )
        setChangeFields({})
        if (storageData) {
          setChangeFields({ ...storageData.data })
          setListData({
            ...listData,
            ...storageData.data,
          })
        } else {
          setListData(listData)
        }
        dispatch({ type: "STATE", courseState: listData.state })
      }
    } else {
      setListData()
    }
  }, [data, dispatch, networkStatus])

  useEffect(() => {
    try {
      if (
        !(
          Object.keys(changeFields).length === 0 &&
          changeFields.constructor === Object
        )
      ) {
        saveToLocalStorage(LOCAL_STORAGE_KEY.course, listData?.id, changeFields)
      }
    } catch {
      // fail gracefully, this is OK
    }
  }, [listData, changeFields])

  const handleCategoryChange = (category) => {
    setChangeFields({ ...changeFields, courseCategory: category })
    setListData({ ...listData, courseCategory: category })
    setChangedState(true)
  }

  const handleChange = (event) => {
    let newVal = event.target.value

    if (event.target.type === "number") {
      newVal = parseInt(newVal)
    }

    if (event.target.type === "checkbox") {
      newVal = event.target.checked
    }
    if (event.target.name === "courseCategory") {
      newVal = { id: newVal }
    }
    setChangeFields({ ...changeFields, [event.target.name]: newVal })
    setListData({ ...listData, [event.target.name]: newVal })
    setChangedState(true)
  }

  const setChangedState = (unsaved) => {
    changeDispatch({ type: "COURSE", id: courseid, unsaved: unsaved })
  }
  const handleCustomChange = (data, name) => {
    setChangeFields({ ...changeFields, [name]: data })
    setListData({ ...listData, [name]: data })
    setChangedState(true)
  }

  const handleDateChange = (data) => {
    setChangeFields({ ...changeFields, approvedDate: data })
    setListData({ ...listData, approvedDate: data })
    setChangedState(true)
  }

  const saveHandler = () => {
    editCourse(
      createVariables(
        {
          id: courseid,
          approvedDate: listData.approvedDate,
          hasJournal: listData.hasJournal,
        },
        changeFields
      )
    )
  }

  const addError = (error) => {
    setErrors((errors) => [...errors, error])
  }

  const [editCourse, { loading: mutationLoading, error: mutationError }] =
    useMutation(UPDATE_COURSE, {
      refetchQueries: [{ query: GET_COURSE_TREE, variables: { id: courseid } }],
      onCompleted(data) {
        const content = data.updateCourse
        if (content.isValid) {
          deleteFromLocalStorage(LOCAL_STORAGE_KEY.course, listData.id)
          setChangeFields({})
          setErrors([])
          setChangedState(false)
        } else {
          setErrors([])
          content.errors.map((data) => addError(data))
          enqueueSnackbar("Course updated failed", { variant: "error" })
        }
      },
    })

  const resetFromCloud = async () => {
    deleteFromLocalStorage(LOCAL_STORAGE_KEY.course, listData.id)
    setChangeFields({})
    setErrors([])
    const data = await refetch()
    setListData({ ...data.data.courseById })
    setChangedState(false)
  }

  const errorSnackbarsComponent = errorSnackbars(errors)

  if (loading || listData === undefined) {
    return <LoadingGeneralSkeleton />
  }

  if (error) {
    return <Redirect to="/error"></Redirect>
  }

  const unsavedChanges = !(
    Object.keys(changeFields).length === 0 &&
    changeFields.constructor === Object
  )
  return (
    <>
      <Tabs
        tabLabels={["Details", "Summary", "Information", "History", "Versions"]}
        tab={tab}
        setTab={setTab}
      />
      <FormHeader
        header={listData.name}
        unsavedChanges={unsavedChanges}
        subHeadings={[
          { label: "Version: ", value: listData.version },
          {
            label: "Created: ",
            value: format(Date.parse(listData.created), "dd-MM-yyyy HH:mm:ss"),
          },
          { label: "By: ", value: listData.createdBy },
          { label: "", value: <DownloadCourseButton id={listData.id} /> },
        ]}
      />

      {errorSnackbarsComponent}

      <TabPanel value={tab} index={0}>
        <CourseDetails
          listData={listData}
          handleChange={handleChange}
          handleCustomChange={handleCustomChange}
          handleCategoryChange={handleCategoryChange}
          category={data?.courseById.courseCategory}
          handleDateChange={handleDateChange}
          fieldRules={fieldRules}
          courseState={courseState}
          state={listData.state !== "PUBLIC" ? statesNotPublic : stateChoices}
        />
      </TabPanel>

      <TabPanel value={tab} index={1}>
        <FormSection className={classes.block}>
          <BaloonBlock
            data={listData.summaryContent}
            border={true}
            name="summaryContent"
            getDataCallback={handleCustomChange}
            disabled={fieldRules?.summaryContent}
          />
        </FormSection>
      </TabPanel>

      <TabPanel value={tab} index={2}>
        <FormSection className={classes.block}>
          <BaloonBlock
            data={listData.information}
            name="information"
            border={true}
            getDataCallback={handleCustomChange}
            disabled={fieldRules?.information}
          />
        </FormSection>
      </TabPanel>

      <TabPanel value={tab} index={3}>
        <History
          key={parseInt(listData.id)}
          id={parseInt(listData.id)}
          open={true}
          type={HISTORY_TYPES.COURSE}
          refetch={refetch}
        />
      </TabPanel>

      <TabPanel value={tab} index={4}>
        <VersionsOverview
          type={listData.categoryType}
          guid={listData.guid}
          id={listData.id}
          courseCategory={listData.courseCategory}
        />
      </TabPanel>

      <FabBlock
        unsavedChanges={unsavedChanges}
        resetCallback={resetFromCloud}
        saveCallback={saveHandler}
        saveLoading={mutationLoading}
        saveError={mutationError}
        id={courseid}
        type={"COURSE"}></FabBlock>
    </>
  )
}

export default CourseInformation
