import React from "react"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  LinearProgress } from "@material-ui/core"

const AlertDeleteDialog = (props) => {
  const { text, callback, variables, open, handleShowing, loading } = props
  const handleDelete = () => {
    callback({ variables: variables })
  }

  const handleClose = (event) => {
    event.stopPropagation()
    handleShowing(false)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {loading && <LinearProgress />}
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
            Cancel
        </Button>
        <Button onClick={handleDelete} disabled={loading && true} color="primary">
            Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AlertDeleteDialog