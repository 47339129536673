import "date-fns"
import React, { useEffect, useCallback } from "react"
import DateFnsUtils from "@date-io/date-fns"
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers"
import {
  TextField,
  MenuItem,
  Grid,
  FormControlLabel,
  Switch,
  Typography,
} from "@material-ui/core"
import BaloonBlock from "../Editors/BaloonBlock"
import FormSection from "../FormSection"
import { paymentPosition, certificateType } from "./DropdownSelects"
import { useQuery } from "@apollo/client"
import { GET_CATEGORIES } from "../../gql"

const CourseDetails = ({
  listData,
  category,
  handleChange,
  handleCustomChange,
  handleDateChange,
  fieldRules,
  courseState,
  state,
}) => {
  const [categories, setCategories] = React.useState([])
  const [categoriesLoaded, setCategoriesLoaded] = React.useState(false)
  const [currentCategory, setCurrentCategory] = React.useState(category.id)

  const { data, loading, networkStatus } = useQuery(GET_CATEGORIES, {
    variables: {
      type: listData.categoryType,
    },
    notifyOnNetworkStatusChange: true,
  })

  const handleCategoryOnChange = (event) => {
    setCurrentCategory(event.target.value)
    handleChange(event)
  }

  const createMappedCategories = useCallback(
    (categories) => {
      const mappedCategories = []
      categories.forEach((parent) => {
        if (currentCategory === parent.id) {
          mappedCategories.push({
            id: parent.id,
            label: parent.name,
            name: parent.name,
          })
        }
        parent.subCategories.forEach((child) => {
          mappedCategories.push({
            id: child.id,
            label: `${parent.name}/${child.name}`,
            name: child.name,
          })
        })
      })

      if (!mappedCategories.find((element) => element.id === currentCategory)) {
        mappedCategories.push({
          id: category.id,
          name: category.name,
          label:
            category?.parent !== null
              ? `${category.parent.name}/${category.name}`
              : `${category.name}`,
        })
      }
      return mappedCategories
    },
    [category.id, category.name, category.parent, currentCategory]
  )

  useEffect(() => {
    if (networkStatus === 7) {
      setCategories(createMappedCategories(data.categoriesByCourseType))
      setCategoriesLoaded(true)
    }
  }, [createMappedCategories, data, networkStatus])

  return (
    <>
      <FormSection heading="General">
        <Grid item container xs={8}>
          <TextField
            name={"name"}
            value={listData.name ?? ""}
            onChange={handleChange}
            disabled={fieldRules?.name}
            variant="outlined"
            label="Name"
            fullWidth
          />
        </Grid>

        <Grid item sm={4}>
          <FormControlLabel
            control={
              <Switch
                checked={!!listData.hasJournal}
                onChange={handleChange}
                name="hasJournal"
                color="primary"
                disabled={fieldRules?.hasJournal}
              />
            }
            label="Has journal"
          />
        </Grid>

        <Grid item sm={12}>
          <TextField
            name={"hTMLMeta"}
            variant="outlined"
            value={listData.hTMLMeta ?? ""}
            onChange={handleChange}
            disabled={fieldRules?.htmlMeta}
            label="HTML Meta"
            fullWidth
            multiline
            minRows={4}
          />
        </Grid>

        <Grid item sm={6}>
          <TextField
            select
            variant="outlined"
            label="State"
            name={"state"}
            value={listData.state ?? ""}
            onChange={handleChange}
            // not allowed to change state when the course have sessions
            disabled={courseState.courseSession || fieldRules?.state}
            fullWidth
          >
            {state.map((option) => (
              <MenuItem key={"state" + option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item sm={6}>
          {!loading && categoriesLoaded && (
            <TextField
              select
              variant="outlined"
              name="courseCategory"
              value={currentCategory ?? ""}
              onChange={handleCategoryOnChange}
              disabled={fieldRules?.courseCategory}
              label="Category"
              fullWidth
            >
              {categories.map((option) => (
                <MenuItem key={"category" + option.id} value={option.id}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          )}
        </Grid>
        <Grid item sm={6}>
          <TextField
            name="externalId"
            fullWidth
            variant="outlined"
            color="primary"
            value={listData?.externalId}
            disabled
            error={!listData?.externalId}
            helperText={
              !listData?.externalId
                ? "Course can not be published without external id"
                : ""
            }
            label="externalId"
          />
        </Grid>
      </FormSection>

      <FormSection heading="Payment">
        <Grid item sm={6}>
          <TextField
            name={"price"}
            type="number"
            disabled={fieldRules?.price}
            value={listData.price ?? ""}
            onChange={handleChange}
            variant="outlined"
            label="Price"
            fullWidth
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            name={"hours"}
            type="number"
            disabled={fieldRules?.hours}
            value={listData.hours ?? ""}
            onChange={handleChange}
            variant="outlined"
            label="Hours / Points"
            fullWidth
          />
        </Grid>

        <Grid item sm={12}>
          <TextField
            fullWidth
            variant="outlined"
            label="Position"
            name={"paymentPosition"}
            select
            value={listData.paymentPosition ?? ""}
            onChange={handleChange}
            disabled={fieldRules?.paymentPosition}
          >
            {paymentPosition.map((option) => (
              <MenuItem key={"payment" + option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item sm={6}>
          <TextField
            fullWidth
            variant="outlined"
            label="Chapter"
            name={"paymentChapterNumber"}
            type="number"
            value={listData.paymentChapterNumber ?? ""}
            onChange={handleChange}
            disabled={
              fieldRules?.paymentChapterNumber ||
              listData.paymentPosition !== "DURING"
            }
          />
        </Grid>

        <Grid item sm={6}>
          <TextField
            fullWidth
            variant="outlined"
            label="Page"
            name={"paymentPageNumber"}
            type="number"
            value={listData.paymentPageNumber ?? ""}
            onChange={handleChange}
            disabled={
              fieldRules?.paymentPageNumber ||
              listData.paymentPosition !== "DURING"
            }
          />
        </Grid>
      </FormSection>

      <FormSection heading="Catalogue">
        <Grid container item>
          <Grid container item spacing={2} sm={8}>
            <Grid item sm={12}>
              <TextField
                fullWidth
                variant="outlined"
                label="Ingress"
                name={"ingress"}
                value={listData.ingress ?? ""}
                onChange={handleChange}
                disabled={fieldRules?.ingress}
              />
            </Grid>

            <Grid item sm={12}>
              <TextField
                fullWidth
                variant="outlined"
                label="Thumbnail"
                name={"thumbnail"}
                value={listData.thumbnail ?? ""}
                onChange={handleChange}
                disabled={fieldRules?.thumbnail}
              />
            </Grid>
          </Grid>
          <Grid item container sm={4} justifyContent="center">
            <img
              alt="thumbnail preview"
              src={listData.thumbnail ?? ""}
              style={{ height: 100 }}
            ></img>
          </Grid>
        </Grid>
      </FormSection>

      <FormSection heading="Certificate">
        <Grid item sm={12}>
          <TextField
            fullWidth
            variant="outlined"
            label="Certificate Type"
            name={"certificateType"}
            select
            value={listData.certificateType ?? ""}
            onChange={handleChange}
            disabled={fieldRules?.certificateType}
          >
            {certificateType.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item sm={12}>
          <Typography variant="caption" gutterBottom>
            Certificate Template
          </Typography>
          <BaloonBlock
            border
            name={"certificateTemplate"}
            data={listData.certificateTemplate}
            getDataCallback={handleCustomChange}
            disabled={fieldRules?.certificateTemplate}
          />
        </Grid>
      </FormSection>
      <FormSection heading="External/Internal Approval">
        <Grid item sm={12}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              label={"Approved Date"}
              format="dd/MM/yyyy"
              margin="normal"
              id="date-picker-inline"
              value={listData.approvedDate}
              onChange={handleDateChange}
              disabled={fieldRules?.approvedDate}
              KeyboardButtonProps={{
                "aria-label": "change date of approval",
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item sm={12}>
          <Typography variant="caption" gutterBottom>
            Approved Text
          </Typography>
          <BaloonBlock
            border
            name={"approvedText"}
            data={listData.approvedText}
            getDataCallback={handleCustomChange}
            disabled={fieldRules?.approvedText}
          />
        </Grid>
      </FormSection>
    </>
  )
}

export default CourseDetails
