import React, { useEffect } from "react"
import { makeStyles } from "@material-ui/core"
import { useParams, Redirect } from "react-router-dom"
import useTitle from "../../hooks/useTitle"
import { useQuery } from "@apollo/client"
import { GET_COURSE_TREE } from "../../gql/get"
import { urlContext } from "../../hooks/urlHooks"
import { useCourseStateDispatch } from "../../contexts"
import {
  Accordion,
  LinearProgress,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Grid,
  Tooltip,
} from "@material-ui/core"
import { TreeView } from "@material-ui/lab"
import {
  ChevronRight as ChevronRightIcon,
  ExpandMore as ExpandMoreIcon,
} from "@material-ui/icons"
import CourseTreeCourse from "./CourseTreeCourse"
import { createUnsavedOverview } from "./../../contexts/courseTree/unsavedObjectsCreater"
import { useChapterUnsavedDispatch } from "../../contexts/courseTree/ChapterUnsavedStateContext"
import { usePageUnsavedDispatch } from "../../contexts/courseTree/PageUnsavedStateContext"
import { useCourseUnsavedDispatch } from "../../contexts/courseTree/CourseUnsavedStateContext"
import { useCourseState } from "../../contexts/CourseStateContext"
import InfoIcon from "@material-ui/icons/Info"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  accordion: {
    marginBottom: "10px",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}))

function visibleText(state, newestCourse) {
  if (state === "PUBLIC" && !newestCourse) {
    return "Not newest course. Still visible for customers if this is the newest public version or customer have sessions in this version"
  }
  if (state === "PUBLIC" && newestCourse) {
    return "Visible for customers"
  }
  if (state === "STAGED" && newestCourse) {
    return "Visible for editors"
  }
  if (state === "STAGED" && !newestCourse) {
    return "Not the newest course. Not visible for customers and editors"
  }
  if (state === "DEVELOPMENT") {
    return "Not visible for customers and editors."
  }
  if (state === "DELETED") {
    return "Deleted, not visible for customers and editors"
  }
  return "Unknown state"
}

const CourseTree = () => {
  const { courseid: paramCourseid, pagenumber, chapternumber } = useParams()
  const startNodes = ["root"]
  if (chapternumber) {
    startNodes.push("chapter/" + chapternumber)
  }
  const courseUnsavedDispatch = useCourseUnsavedDispatch()
  const chapterUnsavedDispatch = useChapterUnsavedDispatch()
  const pageUnsavedDispatch = usePageUnsavedDispatch()
  const dispatch = useCourseStateDispatch()
  // This is used to rerender this component. Do not remove
  const state = useCourseState()

  const { state: urlState, dispatch: urlDispatch } =
    React.useContext(urlContext)
  const { setTitle } = useTitle()
  const [selectedNode, setSelectedNode] = React.useState()
  const [category, setCategory] = React.useState()

  const [expanded, setExpanded] = React.useState(startNodes)
  const variables = { id: parseInt(paramCourseid) }
  const classes = useStyles()
  const { loading, error, data } = useQuery(GET_COURSE_TREE, {
    variables,
  })

  useEffect(() => {
    let buildText = ""
    let type = "COURSE"

    if (urlState.coursename) {
      buildText += urlState.coursename
    }
    if (urlState.chaptername) {
      buildText += " / " + urlState.chaptername
      type = "CHAPTER"
    }
    if (urlState.pagenumber) {
      buildText +=
        " / " + urlState.pagenumber + ": " + urlState.pagetype.toLowerCase()
      type = "PAGE"
    }

    switch (type) {
      case "COURSE":
        setSelectedNode("root")
        break
      case "CHAPTER":
        setSelectedNode("chapter/" + urlState.chapternumber)
        setExpanded(["root", selectedNode])
        break
      case "PAGE":
        setSelectedNode(`${urlState.chapternumber}/page/${urlState.pagenumber}`)
        setExpanded(["root", "chapter/" + urlState.chapternumber, selectedNode])
        break
      default:
        break
    }

    setTitle(buildText)
  }, [urlState, setTitle, selectedNode])

  useEffect(() => {
    if (!loading && data) {
      const chapter = data.courseById.chapters?.find(
        (chap) => chap.number === parseInt(chapternumber)
      )
      const page = chapter?.pages?.find((page) => {
        return page.number === parseInt(pagenumber)
      })
      urlDispatch({
        type: "page",
        courseid: data.courseById.id,
        coursename: data.courseById.name,
        chapterid: chapter?.id,
        chapternumber: chapter?.number,
        chaptername: chapter?.name,
        pagenumber: page?.number,
        pageid: page?.id,
        pagetype: page?.pageType,
      })

      const category =
        data.courseById.courseCategory.parent !== null
          ? `${data.courseById.courseCategory.parent.name} / ${data.courseById.courseCategory.name}`
          : data.courseById.courseCategory?.name
      setCategory(category)
    }
  }, [data, paramCourseid, loading, chapternumber, pagenumber, urlDispatch])

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds)
  }

  useEffect(() => {
    if (!loading && data) {
      dispatch({
        courseState: data.courseById.state,
        courseSession: !!data.courseById.sessionCount,
        courseVersion: data.courseById.version,
      })
      const unsavedTreeItems = createUnsavedOverview(data.courseById)
      courseUnsavedDispatch({ type: "SET", data: unsavedTreeItems.course })
      chapterUnsavedDispatch({ type: "SET", data: unsavedTreeItems.chapter })
      pageUnsavedDispatch({ type: "SET", data: unsavedTreeItems.page })
    }
  }, [
    chapterUnsavedDispatch,
    courseUnsavedDispatch,
    data,
    dispatch,
    loading,
    pageUnsavedDispatch,
  ])

  if (error) {
    return <Redirect to="/error"></Redirect>
  }
  return (
    <Accordion className={classes.accordion} defaultExpanded={true}>
      {loading && <LinearProgress color="primary" />}
      <AccordionSummary expandIcon={<ExpandMoreIcon />} id="tree-header">
        <Typography className={classes.heading}>Overview</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {loading || !data?.courseById ? (
          <LinearProgress color="primary" />
        ) : (
          <TreeView
            className={classes.root}
            defaultCollapseIcon={<ExpandMoreIcon />}
            expanded={expanded}
            onNodeToggle={handleToggle}
            defaultExpandIcon={<ChevronRightIcon />}
            selected={selectedNode}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {category ? (
                  <Typography variant="body2">Category: {category}</Typography>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">
                  Newest version:{" "}
                  {data.courseById.newestCourse ? (
                    <span style={{ color: "green" }}>Yes</span>
                  ) : (
                    <span style={{ color: "red" }}>No</span>
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography component="div" variant="body2">
                  <Grid container direction="row" alignItems="center">
                    State: {data.courseById.state}
                    <Tooltip
                      title={visibleText(
                        data.courseById.state,
                        data.courseById.newestCourse
                      )}
                    >
                      <InfoIcon />
                    </Tooltip>
                  </Grid>
                </Typography>
              </Grid>
              <Grid item xs={12}></Grid>
            </Grid>
            {!loading && <CourseTreeCourse treeData={data.courseById} />}
          </TreeView>
        )}
      </AccordionDetails>
    </Accordion>
  )
}

export default CourseTree
