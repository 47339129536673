import { CREATE_CACHE_COURSE } from "../gql"


const UpdateOverviewWithNewCourse = (cache, { data }) => {
  cache.modify({
    fields: {
      courses(existingRefs = []) {
        const newRef = cache.writeFragment({
          data: data,
          fragment: CREATE_CACHE_COURSE,
        })
        return [...existingRefs, newRef]
      },
    },
  })
}


const UpdateOverviewWithNewCourseClone = (cache, { data }) => {
  cache.modify({
    fields: {
      courses(existingRefs = []) {
        const newRef = cache.writeFragment({
          data: data.cloneCourse,
          fragment: CREATE_CACHE_COURSE,
        })
        return [...existingRefs, newRef]
      },
    },
  })
}


const UpdateOverviewWithNewCourseCloneFromOther = (cache, { data }) => {
  cache.modify({
    fields: {
      courses(existingRefs = []) {
        const newRef = cache.writeFragment({
          data: data.cloneCourseFromOther,
          fragment: CREATE_CACHE_COURSE,
        })
        return [...existingRefs, newRef]
      },
    },
  })
}

const removeFromCache = (cache, id, field) => {
  if (field) {
    cache.evict({ fieldName: field })
  }
  else {
    cache.evict({ id: id })
  }
  cache.gc()
}

export { UpdateOverviewWithNewCourse, removeFromCache, UpdateOverviewWithNewCourseClone, UpdateOverviewWithNewCourseCloneFromOther }