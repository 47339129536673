import React, { useEffect, useState } from "react"
import history from "../../utils/history"
import { DescriptionOutlined, DoneAll, Done, SmsOutlined, InfoOutlined, BlockOutlined } from "@material-ui/icons"
import StyledTreeItem from "./StyledTreeItem"
import UnsavedChangesIcon from "./../Alert/UnsavedChangesIcon"
import { usePageUnsavedState } from "../../contexts/courseTree/PageUnsavedStateContext"


const handlePageNodeClick = (event, value) => {
  event.preventDefault()
  const href = `/course/${value[0]}/chapter/${value[1]}/page/${value[2]}`
  history.push(href)
}

function getIconForType(type) {
  switch (type) {
    case "INFORMATION":
      return InfoOutlined
    case "FREETEXT":
      return DescriptionOutlined
    case "MULTIPLE":
      return DoneAll
    case "SINGLE":
      return Done
    case "REPEAT":
      return SmsOutlined
    default:
      return BlockOutlined
  }
}

const CourseTreePage = ({ page, courseid, chapternumber }) => {
  const pageId = "page/" + page.number.toString()
  const label = page.number + ". " + page.pageType.toLowerCase()
  const icon = getIconForType(page.pageType)
  const state = usePageUnsavedState()
  const [unsaved, setUnsaved] = useState(state[page.id]?.unsaved)
  useEffect(()=> {
    if(unsaved !== state[page.id]?.unsaved) {
      setUnsaved(state[page.id]?.unsaved)
    }
  }, [state, page.id, unsaved])

  const nodeid = `${chapternumber}/${pageId}`

  return (
    <StyledTreeItem
      key={page.id}
      labelIcon={icon}
      labelInfo={unsaved && <UnsavedChangesIcon text={"Unsaved changes to this page"} />}
      nodeId={nodeid}
      labelText={label}
      onLabelClick={(e) => handlePageNodeClick(e, [courseid, chapternumber, page.number])} />
  )
}

export default React.memo(CourseTreePage)