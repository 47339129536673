import React from "react"
import { Skeleton } from "@material-ui/lab"

const GeneralSkeleton = () => {
  return (
    <Skeleton variant="rect" width="100%">
      <div style={{ paddingTop: "60%" }} />
    </Skeleton>
  )
}

export default GeneralSkeleton