import React from "react"
import { CircularProgress } from "@material-ui/core"

const AddResourceLoading = (props) => {
  const { loading } = props

  if(loading) return <CircularProgress />
  return (<></>)
}

export default AddResourceLoading