import React from "react"

import DeleteIcon from "@material-ui/icons/Delete"
import {
  Tooltip,
  Fab
} from "@material-ui/core"

import { useDeleteMutation } from "../../hooks"
import AlertDeleteDialog from "../Dialogs/AlertDeleteDialog"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(() => ({
  pointer: {
    pointerEvents: "auto",
  },
}))


// eslint-disable-next-line react/display-name
const DeleteFab = React.memo(({ type, id }) => {
  const [open, setOpen] = React.useState(false)
  const classes = useStyles()

  const handleShowingDialog= (state) => {
    setOpen(state)
  }
  const handleOpenDialog = () => {
    setOpen(true)
  }

  const [deleteObject, { loading }] = useDeleteMutation(type)

  return (
    <>
      <Tooltip title={"Delete " + type.toLowerCase()} aria-label="delete">
        <Fab className={classes.pointer} color="primary" aria-label="delete" disabled={loading} onClick={handleOpenDialog}>
          <DeleteIcon />
        </Fab>
      </Tooltip>
      <AlertDeleteDialog handleShowing={handleShowingDialog} open={open} data={{ type, id }} text={"You are about to delete "+ type.toLowerCase() +". This deletion is permanent and can not be reversed"} callback={deleteObject} variables={{ id: id }} loading={loading}></AlertDeleteDialog>
    </>
  )
})

export default DeleteFab


