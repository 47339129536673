import React from "react"
import { useMutation } from "@apollo/client"
import { CircularProgress, Tooltip } from "@material-ui/core"
import { useSnackbar } from "notistack"
import { PUBLISH_COURSE, COURSE_INFORMATION } from "../../gql"
import { Publish as PublishIcon } from "@material-ui/icons"
import Fab from "@material-ui/core/Fab"
import { publishStyleFab } from "./Styles"

const PublishFab = (props) => {
  const { courseId } = props
  const { enqueueSnackbar } = useSnackbar()
  const [clicked, setClicked] = React.useState(false)
  const classes = publishStyleFab()

  const handleClick = () => {
    setClicked(true)
    publishCourse()
  }

  const [
    publishCourse,
    { loading: mutationLoading, error: mutationError },
  ] = useMutation(PUBLISH_COURSE, {
    variables: { id: courseId },
    refetchQueries: [{
      query:COURSE_INFORMATION,
      variables: { CourseId: parseInt(courseId) },
    }],
    onCompleted(data) {
      if (data?.publishCourse) {
        console.log("Publish course")
        enqueueSnackbar("Published course successfully", { variant: "success" })
      } else {
        console.log("not published")
        enqueueSnackbar("Publish course failed", { variant: "error" })
      }
    },
  })

  return (
    <Tooltip title="Publish course" aria-label="Publish Course">
      <Fab
        className={classes.warning}
        color={!mutationError ? "primary" : "secondary"}
        disabled={(clicked || mutationError || mutationLoading)}
        onClick={handleClick}
      >
        {!mutationLoading ? <PublishIcon /> : <CircularProgress />}
      </Fab>
    </Tooltip>
  )
}

export default PublishFab