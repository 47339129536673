const rules = (state) => {
	if (state === "DEVELOPMENT") {
		return baseRestrictedFields
	}
	if (state === "STAGED") {
		return {
			course: {
				...baseRestrictedFields.course,
				hasJournal: true,
			},
			chapter: {
				...baseRestrictedFields.chapter,
				number: true,
				percentToPass: true,
				chapterType: true,
			},
			page: {
				...baseRestrictedFields.page,
				number: true,
				pageType: true,
				repeat: true,
				orderAlternativesRandom: false,
			},
			alternative: {
				...baseRestrictedFields.alternative,
				points: true,
				number: false,
			},
		}
	}
	if (state === "PUBLIC") {
		return {
			course: {
				...baseRestrictedFields.course,
				hasJournal: true,
				price: false,
				hours: false,
				paymentPosition: false,
				paymentChapterNumber: false,
				paymentPageNumber: false,
				courseCategory: false,
			},
			chapter: {
				...baseRestrictedFields.chapter,
				number: true,
				percentToPass: true,
				chapterType: true,
			},
			page: {
				...baseRestrictedFields.page,
				number: true,
				pageType: true,
				orderAlternativesRandom: false,
				repeat: true,
				excludeFromTest: true,
			},
			alternative: {
				...baseRestrictedFields.alternative,
				points: true,
				number: false,
			},
		}
	}
	return allRestrictedFields
}

const allRestrictedFields = {
	course: {
		state: true,
		hasJournal: true,
		name: true,
		thumbnail: true,
		ingress: true,
		information: true,
		categoryType: true,
		certificateType: true,
		certificateTemplate: true,
		summaryContent: true,
		hours: true,
		price: true,
		paymentPosition: true,
		paymentChapterNumber: true,
		paymentPageNumber: true,
		htmlMeta: true,
		courseCategory: true,
		approvedText: true,
		approvedDate: true,
	},
	chapter: {
		number: true,
		name: true,
		percentToPass: true,
		maximumScore: true,
		chapterType: true,
		courseId: true,
	},
	page: {
		number: true,
		pageType: true,
		content: true,
		response: true,
		question: true,
		repeat: true,
		excludeFromTest: true,
		maximumScore: true,
		courseChapterId: true,
		orderAlternativesRandom: true,
	},
	alternative: {
		text: true,
		response: true,
		points: true,
		coursePageId: true,
		number: true,
	},
}

const baseRestrictedFields = {
	course: {
		state: false,
		hasJournal: false,
		name: false,
		thumbnail: false,
		ingress: false,
		information: false,
		categoryType: false,
		certificateType: false,
		certificateTemplate: false,
		summaryContent: false,
		hours: false,
		price: false,
		paymentPosition: false,
		paymentChapterNumber: false,
		paymentPageNumber: false,
		htmlMeta: false,
		courseCategory: false,
		approvedText: false,
		approvedDate: false,
	},
	chapter: {
		number: false,
		name: false,
		percentToPass: false,
		maximumScore: false,
		chapterType: false,
		courseId: false,
	},
	page: {
		number: false,
		pageType: false,
		content: false,
		response: false,
		question: false,
		repeat: false,
		excludeFromTest: false,
		maximumScore: false,
		courseChapterId: false,
		orderAlternativesRandom: false,
	},
	alternative: {
		text: false,
		response: false,
		points: false,
		coursePageId: false,
		number: false,
	},
}

export default rules
