import React, { useState, useEffect } from "react"

import { useQuery, useMutation } from "@apollo/client"
import { MenuItem, TextField, Grid } from "@material-ui/core"
import { format } from "date-fns"
import { useSnackbar } from "notistack"
import { useParams } from "react-router-dom"
import { Redirect } from "react-router-dom"

import HISTORY_TYPES from "../History/HISTORY_TYPES"
import { errorSnackbars } from "../../utils/ErrorResponseHandler"
import FabBlock from "../Fab/FabBlock"
import { GET_COURSE_CHAPTER_BY_NUMBER, UPDATE_COURSE_CHAPTER, GET_COURSE_TREE } from "../../gql"
import { getFromLocalStorage, deleteFromLocalStorage, LOCAL_STORAGE_KEY, saveToLocalStorage } from "../../utils/localstorage"
import { LoadingGeneralSkeleton } from "./../Skeletons"
import { useCourseState } from "../../contexts"
import rules from "../../course-rules"
import GoToNew from "../../utils/GoToNew"
import { Tabs, TabPanel } from "../Tabs"
import History from "../History/History"
import FormSection from "../FormSection"
import FormHeader from "../FormHeader"
import createVariables from "../../utils/createVariables"
import { useChapterUnsavedDispatch } from "../../contexts/courseTree/ChapterUnsavedStateContext"
import { chapterType } from "./DropdownSelects"
import DownloadChapterButton from "../Download/DownloadChapterButton"


const CourseChapter = () => {
  const { courseid, chapternumber } = useParams()
  const courseId = parseInt(courseid)
  const chapterNumber = parseInt(chapternumber)
  const { enqueueSnackbar } = useSnackbar()
  const [listData, setListData] = React.useState()
  const [errors, setErrors] = useState([])
  const [changeFields, setChangeFields] = React.useState({})
  const [changedNumbers, setChangeNumbers] = React.useState()
  const [tab, setTab] = React.useState(0)
  const courseState = useCourseState()
  const fieldRules = rules(courseState.courseState)?.chapter
  const changesDispatch = useChapterUnsavedDispatch()

  const { loading, error, refetch, data } = useQuery(GET_COURSE_CHAPTER_BY_NUMBER, {
    variables: { CourseId: courseId, ChapterNumber: chapterNumber },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  })

  const setChangedState = React.useCallback((unsaved, id) => {
    changesDispatch({ type: "CHAPTER", id: id, unsaved: unsaved })
  }, [changesDispatch])

  useEffect(() => {
    if (changedNumbers && (Object.keys(listData).length !== 0 && listData.constructor === Object)) {
      setChangeNumbers()
      GoToNew({ id: listData.courseId, chapterNumber: changedNumbers })
    }
  }, [changedNumbers, listData])

  useEffect(() => {
    if (data) {
      const listData = { ...data.chapterByCourseAndNumber }
      const storageData = getFromLocalStorage(LOCAL_STORAGE_KEY.chapter, listData.id)
      setChangeFields({})
      setErrors([])
      if (storageData) {
        setChangeFields({ ...storageData.data })
        setListData({
          ...listData,
          ...storageData.data,
        })
        setChangedState(true, listData.id)
      } else {
        setListData(listData)
        setChangedState(false, listData.id)
      }
    } else {
      setListData()
    }
  }, [data, setChangedState])


  useEffect(() => {
    try{
      if ((Object.keys(changeFields).length !== 0 && changeFields.constructor === Object) && (Object.keys(listData).length !== 0 && listData.constructor === Object)) {
        saveToLocalStorage(LOCAL_STORAGE_KEY.chapter, listData?.id, changeFields)
      }
    } catch {
      // Fail gracefully, this is OK
    }
  }, [listData, changeFields])

  const handleChange = (event) => {
    let newVal = event.target.value
    if (event.target.type === "number") {
      newVal = parseInt(newVal)
      if (event.target.name === "number") {
        newVal = newVal > 1 ? newVal : 1
      }
    }
    if(event.target.name === "chapterType" && newVal === "TEST") {
      setChangeFields({ ...changeFields, [event.target.name]: newVal, percentToPass: 0 })
      setListData({ ...listData, [event.target.name]: newVal, percentToPass: 0 })
    } else {
      setChangeFields({ ...changeFields, [event.target.name]: newVal })
      setListData({ ...listData, [event.target.name]: newVal })
    }
    setChangedState(true, listData.id)
  }

  const saveHandler = () => {
    if (!(Object.keys(changeFields).length === 0 && changeFields.constructor === Object)) {
      editChapter(
        createVariables({ id: listData.id }, changeFields)
      )
    }
  }

  const addError = (error) => {
    setErrors(errors => [...errors, error])
  }

  const [
    editChapter,
    { loading: mutationLoading, error: mutationError },
  ] = useMutation(UPDATE_COURSE_CHAPTER,
    {
      refetchQueries: [
        { query: GET_COURSE_TREE, variables: { id: courseId } },
      ],
      onCompleted(data) {
        const content = data.updateChapter
        if (content?.isValid) {
          deleteFromLocalStorage(LOCAL_STORAGE_KEY.chapter, listData.id)
          setChangeFields({})
          setErrors([])
          setChangedState(false, listData.id)
          if (changeFields["number"]) {
            setChangeNumbers(changeFields["number"])
          }
        } else {
          setErrors([])
          content.errors.map(data => addError(data))
          enqueueSnackbar("Course chapter updated failed", { variant: "error" })
        }
      },
    })

  const resetFromCloud = async () => {
    deleteFromLocalStorage(LOCAL_STORAGE_KEY.chapter, listData.id)
    setChangeFields({})
    setErrors([])
    const data = await refetch()
    setListData({ ...(data.data.chapterByCourseAndNumber) })
    setChangedState(false, listData.id)
  }

  const errorSnackbarsComponent = errorSnackbars(errors)

  if (loading || listData === undefined) {
    return <LoadingGeneralSkeleton />
  }

  if (error) {
    return <Redirect to="/error"></Redirect>
  }

  const unsavedChanges = !(Object.keys(changeFields).length === 0 && changeFields.constructor === Object)
  return (
    <>
      <Tabs
        tabLabels={["Details", "History"]}
        tab={tab}
        setTab={setTab}
      />

      <FormHeader
        header={listData.name}
        unsavedChanges={unsavedChanges}
        subHeadings={[
          { label: "Created: ", value: listData.created && format(Date.parse(listData.created), "dd-MM-yyyy HH:mm:ss") },
          { label: "By: ", value: listData.createdBy },
          { label: "Maximum Score: ", value: listData.maximumScore },
          { label: "", value: (<DownloadChapterButton id={listData.id} />) },
        ]}
      />

      {errorSnackbarsComponent}

      <TabPanel value={tab} index={0}>
        <FormSection heading="General">
          <Grid item xs={8}>
            <TextField
              label="Name"
              name={"name"}
              value={listData.name ?? ""}
              onChange={handleChange}
              disabled={fieldRules?.name}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              type="number"
              label="Number"
              name={"number"}
              value={listData.number ?? ""}
              onChange={handleChange}
              disabled={fieldRules?.number || listData.chapterType === "TEST"}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              select
              label="Chapter Type"
              name={"chapterType"}
              value={listData.chapterType ?? ""}
              onChange={handleChange}
              variant="outlined"
              disabled={fieldRules?.chapterType}
              fullWidth
            >
              {chapterType.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <TextField
              type="number"
              label="Percent To Pass"
              name={"percentToPass"}
              value={listData.percentToPass ?? ""}
              onChange={handleChange}
              disabled={fieldRules?.percentToPass || listData.chapterType === "TEST"}
              variant="outlined"
              helperText="Test chapters should have 0 percent to pass"
              fullWidth
            />
          </Grid>
        </FormSection>
      </TabPanel>

      <TabPanel value={tab} index={1}>
        <History key={parseInt(listData.id)} refetch={refetch} id={parseInt(listData.id)} open={true} type={HISTORY_TYPES.COURSECHAPTER} />
      </TabPanel>

      <FabBlock resetCallback={resetFromCloud} unsavedChanges={unsavedChanges} saveCallback={saveHandler} saveLoading={mutationLoading} saveError={mutationError} id={listData.id} type={"CHAPTER"}></FabBlock>
    </>
  )
}

export default CourseChapter