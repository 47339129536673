import history from "./history"

const GoToNew = (navigationData) => {

  if(navigationData.overview) {
    history.push("/")
    return
  }
  if (navigationData.id) {
    if (navigationData.chapterNumber) {
      if (navigationData.pageNumber) {
        history.push(`/course/${navigationData.id}/chapter/${navigationData.chapterNumber}/page/${navigationData.pageNumber}`)
        return
      }
      history.push(`/course/${navigationData.id}/chapter/${navigationData.chapterNumber}`)
      return
    }
    history.push(`/course/${navigationData.id}`)
    return
  }
  else {
    history.push("/error/")
    return
  }
}

export default GoToNew