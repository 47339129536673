import React from "react"
import CourseInformation from "../components/CourseInformation"
import CourseTree from "../components/CourseTree"
import { SkeletonEditLayout } from "../components/Skeletons"
import ResourceSidebar from "../components/ResourceSidebar/ResourceSidebar"
import CourseChapter from "../components/CourseChapter"
import CoursePageView from "../components/CoursePage"
import { useRouteMatch } from "react-router-dom"
import { editPaths } from "../App"
import ValidationLayout from "./../components/Validate/ValidationLayout"

const getContentType = (matchPage, matchChapter, matchCourse) => {
  if(matchPage && matchPage.isExact)
    return <CoursePageView />
  if(matchChapter && matchChapter.isExact)
    return <CourseChapter />
  if(matchCourse && matchCourse.isExact)
    return <CourseInformation />
}

const EditRoutingComponent = () => {
  const matchPage = useRouteMatch(editPaths[0])
  const matchChapter = useRouteMatch(editPaths[1])
  const matchCourse = useRouteMatch(editPaths[2])

  return (
    <SkeletonEditLayout Content={getContentType(matchPage, matchChapter, matchCourse)} Tree={<CourseTree/>} Resource={<ResourceSidebar />} Validation={<ValidationLayout />}/>
  )
}


export default EditRoutingComponent

export const COMPONENT_TYPE= {
  COURSE: "COURSE",
  CHAPTER: "CHAPTER",
  PAGE: "PAGE",
}