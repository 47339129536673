import React, { useEffect } from "react"
import { TextField, CircularProgress } from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { useLazyQuery } from "@apollo/client"
import { SEARCH_RESOURCE } from "../../gql"

const SearchResourceBar = ({ callback }) => {
  const [open, setOpen] = React.useState(false)
  const [options, setOptions] = React.useState([])
  const [inputValue, setInputValue] = React.useState("")

  const [searchResource, { loading: searchLoading }] = useLazyQuery(
    SEARCH_RESOURCE,
    {
      variables: { searchString : inputValue },
      onCompleted(data) {
        if(data) {
          setOptions(data.searchResources)
        } else {
          console.log("Search failed")
        }
      },
    })

  useEffect(() => {
    if (!open) {
      setOptions([])
    }
  }, [open])

  return (
    <Autocomplete
      id="resource-search"
      style={{ width: 300 }}
      open={open}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      getOptionSelected={(option, value) => option.name === value.name}
      getOptionLabel={(option) => option.name }
      noOptionsText="No resources found"
      onChange= { (event, newValue) => {
        callback(newValue)
      }}
      onInputChange= {(event, newInputValue) => {
        setInputValue(newInputValue)
        if(newInputValue.length > 2) {
          searchResource()
        }
      }}
      options={options}
      loading={searchLoading}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search resource"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {searchLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}

export default SearchResourceBar
