import React, { useEffect } from "react"
import { Redirect } from "react-router-dom"

import { useQuery } from "@apollo/client"
import MaterialTable from "material-table"

import { GET_HISTORY, GET_TOPLEVEL_HISTORY } from "../../gql"
import HISTORY_TYPES from "./HISTORY_TYPES"
import DiffDialog from "../Dialogs/DiffDialog"
import { RevertDialog } from "../Dialogs"

// BUG: Hvis du bruker filter på en column, går til siste side, går alle sidene tilbake stegvis, så blir henter db 11 elementer når du kommer til side nr 2 av en grunn, material table viser bare 10 og 1 element er tapt hvis du ikke går til side 1 og så til side 2
const History = (props) => {
  // https://github.com/mbrn/material-table/issues/890
  // https://github.com/pixelass/phony/blob/master/examples/apollo/pages/index.tsx#L43
  const [id] = React.useState(props.id)
  const [type] = React.useState(props.type)
  const refetchParent = props.refetch
  const [page, setPage] = React.useState(0)
  const [currentPage, setCurrentPage] = React.useState()
  const [previousPage, setpreviousPage] = React.useState("")
  const [currentPageSize, setCurrentPageSize] = React.useState(10)
  const [chosenPageSize, setChosenPageSize] = React.useState(10)
  const [totalCount, setTotalCount] = React.useState(0)
  const [startCursor, setStartCursor] = React.useState("")
  const [endCursor, setEndCursor] = React.useState("")

  const variables = React.useMemo(() => {
    const orderBy = { modified: "DESC" }

    if (previousPage) {
      return {
        Id: id,
        Type: type,
        Before: previousPage === true ? null : previousPage,
        Last: currentPageSize,
        Order_by: orderBy,
      }
    }
    return {
      Id: id,
      Type: type,
      After: currentPage,
      First: currentPageSize,
      Order_by: orderBy,
    }
  }, [id, type, currentPage, currentPageSize, previousPage])

  const [mappedData, setMappedData] = React.useState([])
  // BUG: Hvis alle de forrige callene på et filter returnerer en tom liste, og du endlig får noe i lista så vil data bli fetchet men ikke vist i materialtable
  const query =
    type === HISTORY_TYPES.TOPLEVEL ? GET_TOPLEVEL_HISTORY : GET_HISTORY
  const { loading, refetch, error, data } = useQuery(query, {
    variables,
  })

  useEffect(() => {
    if (props.open) {
      refetch()
    }
  }, [props.open, refetch])
  useEffect(() => {
    if (data) {
      const mappedData =
        type === HISTORY_TYPES.TOPLEVEL
          ? data.topLevelHistory.edges.map((_) => ({ ..._.node }))
          : data.history.edges.map((_) => ({ ..._.node }))
      mappedData.forEach((parent) => {
        const changes = {}
        changes.data = JSON.parse(parent.changes)
        changes.fields = changes.data.map((_) => _.FieldName).toString()
        changes.data = changes.data.map((_) => ({
          id: parent.id,
          FieldName: _.FieldName,
          ValueBefore: _.ValueBefore,
          ValueAfter: _.ValueAfter,
        }))
        parent.changes = changes
      })
      setMappedData(mappedData)
      switch (type) {
        case HISTORY_TYPES.TOPLEVEL:
          setTotalCount(data.topLevelHistory.totalCount)
          setStartCursor(data.topLevelHistory.pageInfo.startCursor)
          setEndCursor(data.topLevelHistory.pageInfo.endCursor)
          break
        default:
          setTotalCount(data.history.totalCount)
          setStartCursor(data.history.pageInfo.startCursor)
          setEndCursor(data.history.pageInfo.endCursor)
      }
    } else {
      setMappedData([])
    }
  }, [data, type])

  const fields =
    type === HISTORY_TYPES.TOPLEVEL
      ? [
          {
            title: "Type",
            field: "table",
          },
          {
            title: "Modified",
            field: "modified",
            defaultSort: "desc",
            type: "datetime",
          },
          {
            title: "Modified by",
            field: "modifiedBy",
          },
          {
            title: "Changed Fields",
            field: "changes.fields",
          },
        ]
      : [
          {
            title: "Modified",
            field: "modified",
            defaultSort: "desc",
            type: "datetime",
          },
          {
            title: "Modified by",
            field: "modifiedBy",
          },
          {
            title: "Changed Fields",
            field: "changes.fields",
          },
        ]

  if (error) {
    console.log(error)
    return <Redirect to="/error"></Redirect>
  }
  if (!error && !loading) {
    if (data?.pageByCourseIdChapterNumberPageNumber === null) {
      return <Redirect to="/error"></Redirect>
    }
  }
  return (
    <MaterialTable
      options={{
        pageSize: currentPageSize,
        pageSizeOptions: [10, 20, 50, 100],
        filtering: false,
        search: false,
        sorting: true,
      }}
      columns={fields}
      isLoading={loading}
      totalCount={
        data
          ? type === HISTORY_TYPES.TOPLEVEL
            ? data.topLevelHistory.totalCount
            : data.history.totalCount
          : undefined
      }
      page={page}
      onChangePage={(_) => {
        // First page
        if (_ === 0) {
          setCurrentPageSize(chosenPageSize)
          setCurrentPage(null)
          setpreviousPage(null)
          // last page
        } else if (_ + 1 > totalCount / currentPageSize) {
          setCurrentPageSize(totalCount % (_ * currentPageSize))
          setpreviousPage(true)
        } else if (_ < page) {
          //Previous
          setCurrentPageSize(chosenPageSize)
          setpreviousPage(startCursor)
        } else {
          // Next
          setCurrentPageSize(chosenPageSize)
          setCurrentPage(endCursor)
          setpreviousPage(null)
        }
        setPage(_)
      }}
      onChangeRowsPerPage={(nextPerPage) => {
        setCurrentPageSize(nextPerPage)
        setChosenPageSize(nextPerPage)
        setCurrentPage(null)
        setpreviousPage(null)
        setPage(0)
      }}
      actions={[
        {
          icon: "clear",
          tooltip: "Revert all changes made to this history",
          onClick: () => undefined,
        },
      ]}
      components={{
        // eslint-disable-next-line react/display-name
        Action: (props) => {
          if (props.action.icon === "clear") {
            return (
              <RevertDialog
                data={props.data}
                type="multiple"
                align={true}
                refetch={refetchParent}
              />
            )
          }
        },
      }}
      data={mappedData}
      detailPanel={[
        {
          tooltip: "Show Changes",
          // eslint-disable-next-line react/display-name
          render: (rowData) => {
            return (
              <MaterialTable
                options={{
                  search: false,
                  showTitle: false,
                  toolbar: false,
                  showFirstLastPageButtons: false,
                  filtering: false,
                  rowStyle: {
                    verticalAlign: "top",
                    textAlign: "center",
                  },
                }}
                columns={[
                  { title: "Changed field", field: "FieldName" },
                  {
                    title: "Before",
                    field: "ValueBefore",
                  },
                  {
                    title: "After",
                    field: "ValueAfter",
                  },
                ]}
                data={rowData.changes.data}
                title="Changes"
                actions={[
                  {
                    icon: "save",
                    tooltip: "Visualize changes",
                    onClick: () => undefined,
                    // eslint-disable-next-line react/display-name
                  },
                  {
                    icon: "clear",
                    tooltip: "Revert all changes made to this history",
                    onClick: () => undefined,
                  },
                ]}
                components={{
                  // eslint-disable-next-line react/display-name
                  Action: (props) => {
                    if (props.action.icon === "save") {
                      return (
                        <DiffDialog
                          before={props.data.ValueBefore}
                          after={props.data.ValueAfter}
                          title={`Changes made to ${props.data.FieldName}`}
                        ></DiffDialog>
                      )
                    }
                    if (props.action.icon === "clear") {
                      return (
                        <RevertDialog
                          data={props.data}
                          refetch={refetchParent}
                        />
                      )
                    }
                  },
                }}
              />
            )
          },
        },
      ]}
      title="History"
    />
  )
}

export default History
