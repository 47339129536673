import { gql } from "@apollo/client"


export const VALIDATE_COURSE = gql`
  query($id: Int!) { 
    validateCourse(id: $id) {
      isValid
      alternativeValidation {
        id,
        pageNumber,
        chapterNumber
        validationResult {
          errors {
            errorMessage
          }
        }
      }
      chapterValidation {
        id,
        pageNumber,
        chapterNumber
        validationResult {
          errors {
            errorMessage
          }
        }
      }
      pageValidation {
        id,
        pageNumber,
        chapterNumber
        validationResult {
          errors {
            errorMessage
          }
        }
      }
      courseValidation {
        errors {
          errorMessage
        }
      }
    }
  }
`
export const VALIDATE_RESOURCES = gql`
  query ($id: Int!) {
    checkLinkResourceValidation(id: $id) {
      isValid
      name
      statusCode
      url
    }
  }
`

export const VALIDATE_COURSE_LINKS = gql`
  query ($id: Int!) { 
    checkLinkValidityInCourse(id: $id) {
      courseValidation{
        pageNumber,
        chapterNumber
        statusCode
        isValid
        url
      }
      chapterValidation {
        pageNumber,
        chapterNumber
        statusCode
        isValid
        url
      }
        pageValidation {
        pageNumber,
        chapterNumber
        statusCode
        isValid
        url
      }
      alternativeValidation {
        pageNumber,
        chapterNumber
        statusCode
        isValid
        url
      }
    }
  }
`