import React from "react"
import { useAuth0 } from "../../utils/oauth"
import { Link as RouterLink } from "react-router-dom"
import { Menu, MenuItem, AppBar, Link, Typography, Toolbar, Avatar } from "@material-ui/core"
import CssBaseline from "@material-ui/core/CssBaseline"
import PersonIcon from "@material-ui/icons/Person"
import NHILogo from "./../Logo/NHILogo"
import { stylesNavBar } from "./Styles"


const NavBar = () => {
  const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0()
  const classes = stylesNavBar()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const logoutHandler = () => {
    logout()
  }

  const loginWithRedirectHandler = () => {
    loginWithRedirect({})
  }
  const popup = (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      {isAuthenticated
        && <MenuItem onClick={logoutHandler}>Logout</MenuItem>
      }
      {!isAuthenticated
          && <MenuItem onClick={loginWithRedirectHandler}> Log in </MenuItem>
      }
    </Menu>
  )

  return (
    <>
      <CssBaseline />
      <AppBar position="static" elevation={ 1 } className={classes.appBar} >
        <Toolbar color="inherit" className={classes.toolbar}>
          <div className={classes.logo}>
            <NHILogo classes={classes} />
          </div>
          <Typography variant="h2" className={classes.toolbarTitle}>
            Theseus Course Editor
          </Typography>
          <Typography className={classes.link} >
            <Link color="inherit" component={RouterLink} to="/">
              Physicians
            </Link>
          </Typography>
          <Typography className={classes.link} >
            <Link color="inherit" component={RouterLink} to="/healthprofessionals">
              Health Professionals
            </Link>
          </Typography>
          <Typography className={classes.link} >
            <Link color="inherit" component={RouterLink} to="/generalpublic">
              General Public
            </Link>
          </Typography>
          <Avatar onClick={handleClick} className={classes.avatar}>
            <PersonIcon />
          </Avatar>
          {popup}
          {isAuthenticated
          && <div className={classes.link}>
            {user?.name}
          </div>
          }
        </Toolbar>
      </AppBar>
    </>
  )
}

export default NavBar