import React from "react"
import { Paper, Grid, Typography, makeStyles } from "@material-ui/core"

const useStyles = makeStyles(({ spacing }) => ({
  paper: {
    marginBottom: spacing(2),
    padding: spacing(3),
  },
}))

const FormSection = ({ heading, children, switchProp }) => {
  const classes = useStyles()

  return (
    <Paper square elevation={2} className={classes.paper}>
      <Grid container spacing={2}>
        {heading
          ? <Grid item xs={12} container>
            <Grid item xs={6}>
              <Typography variant="overline">{heading}</Typography>
            </Grid>
            <Grid item xs={6}>
              {switchProp ? switchProp : null}
            </Grid>
          </Grid>
          : null}
        {children}
      </Grid>
    </Paper>
  )
}

export default FormSection