import React, { useReducer } from "react"

const ChapterUnsavedStateContext = React.createContext()
const ChapterUnsavedDispatchContext = React.createContext()

const chapterUnsavedReducer = (state, action) => {
  const id = action.id?.toString()

  switch(action.type){
    case "CHAPTER":
      return {
        ...state,
        [id]: {
          unsaved: action.unsaved,
        },
      }
    case "SET":
      return { ...action.data }
    default:
      return { ...state }
  }
}

const ChapterUnsavedStateProvider = ({ children }) => {

  const [state, dispatch] = useReducer(chapterUnsavedReducer, {})

  return (
    <ChapterUnsavedStateContext.Provider value={state}>
      <ChapterUnsavedDispatchContext.Provider value={dispatch}>
        {children}
      </ChapterUnsavedDispatchContext.Provider>
    </ChapterUnsavedStateContext.Provider>
  )
}

function useChapterUnsavedState() {
  const context = React.useContext(ChapterUnsavedStateContext)
  if (context === undefined) {
    throw new Error("usePageUnsavedState must be used within a ")
  }
  return context
}

function useChapterUnsavedDispatch() {
  const context = React.useContext(ChapterUnsavedDispatchContext)
  if (context === undefined) {
    throw new Error("useUnsavedChangesDispatch must be used within a UnsavedChangesProvider")
  }
  return context
}

export { ChapterUnsavedStateProvider, useChapterUnsavedDispatch, useChapterUnsavedState }