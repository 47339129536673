import React, { useReducer } from "react"

const CourseUnsavedStateContext = React.createContext()
const CourseUnsavedDispatchContext = React.createContext()

const courseUnsavedReducer = (state, action) => {
  const id = action.id?.toString()

  switch(action.type){
    case "COURSE":
      return {
        ...state,
        [id]: {
          unsaved: action.unsaved,
        },
      }
    case "SET":
      return { ...action.data }
    default:
      return { ...state }
  }
}

const CourseUnsavedStateProvider = ({ children }) => {

  const [state, dispatch] = useReducer(courseUnsavedReducer, {})

  return (
    <CourseUnsavedStateContext.Provider value={state}>
      <CourseUnsavedDispatchContext.Provider value={dispatch}>
        {children}
      </CourseUnsavedDispatchContext.Provider>
    </CourseUnsavedStateContext.Provider>
  )
}

function useCourseUnsavedState() {
  const context = React.useContext(CourseUnsavedStateContext)
  if (context === undefined) {
    throw new Error("usePageUnsavedState must be used within a ")
  }
  return context
}

function useCourseUnsavedDispatch() {
  const context = React.useContext(CourseUnsavedDispatchContext)
  if (context === undefined) {
    throw new Error("useUnsavedChangesDispatch must be used within a UnsavedChangesProvider")
  }
  return context
}

export { CourseUnsavedStateProvider, useCourseUnsavedDispatch, useCourseUnsavedState }