import React from "react"
import CourseAlternative from "./CourseAlternative"
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core"
import { useCourseState } from "../../contexts"
import rules from "../../course-rules"
import { DragDropContext, Droppable } from "react-beautiful-dnd"
import {
  saveToLocalStorage,
  getFromLocalStorage,
  LOCAL_STORAGE_KEY,
} from "../../utils/localstorage"

const AlternativeList = ({
  saving,
  alternativeData,
  notifyChange,
  disablePoints,
  courseId,
  chapterNumber,
  pageNumber,
  orderAlternativesRandom,
  setBlockData,
}) => {
  const courseState = useCourseState()
  const ruleList = rules(courseState.courseState, courseState.courseSession)
  const alternativeRules = ruleList?.alternative

  const onDragEnd = (result) => {
    const { source, destination } = result

    // dropped outside the list
    if (!destination) {
      return
    }

    const startIndex = source.index
    const endIndex = destination.index

    const newArray = Array.from(alternatives)
    const [removed] = newArray.splice(startIndex, 1)
    newArray.splice(endIndex, 0, removed)

    setBlockData(
      newArray.map((alternative, index) => {
        if (!(alternative.number === index + 1)) {
          const prevChanges = getFromLocalStorage(
            LOCAL_STORAGE_KEY.alternative,
            alternative.id
          )
          saveToLocalStorage(LOCAL_STORAGE_KEY.alternative, alternative.id, {
            ...prevChanges?.data,
            number: index + 1,
          })
        }
        return { ...alternative, number: index + 1 }
      }),
      "alternatives"
    )
  }

  let alternatives

  if (
    !orderAlternativesRandom &&
    alternativeData.some((alt) => alt.number === 0) &&
    !saving
  ) {
    const newAlternatives = Array.from(alternativeData)
    alternatives = newAlternatives
      .sort((a, b) => a.number - b.number)
      .map((alternative, index) => {
        if (!(alternative.number === index + 1)) {
          const prevChanges = getFromLocalStorage(
            LOCAL_STORAGE_KEY.alternative,
            alternative.id
          )
          saveToLocalStorage(LOCAL_STORAGE_KEY.alternative, alternative.id, {
            ...prevChanges?.data,
            number: index + 1,
          })
        }
        return { ...alternative, number: index + 1 }
      })
  } else {
    alternatives = [...alternativeData].sort((a, b) => a.number - b.number)
  }

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Answer</TableCell>
            <TableCell>Response</TableCell>
            <TableCell>Points</TableCell>
            {/* {!orderAlternativesRandom ? <TableCell>Number</TableCell> : null} */}
          </TableRow>
        </TableHead>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={"alternatives"}>
            {(provided) => (
              <TableBody
                ref={provided.innerRef}
                style={{
                  width: "100%",
                }}
              >
                {alternatives?.map((alternative, index) => {
                  const key = "alt" + alternative.id
                  return (
                    <CourseAlternative
                      key={key}
                      alternativeKey={key}
                      index={index}
                      alternative={alternative}
                      notifyChange={notifyChange}
                      courseState={courseState}
                      disablePoints={disablePoints}
                      rules={alternativeRules}
                      courseId={courseId}
                      chapterNumber={chapterNumber}
                      pageNumber={pageNumber}
                      orderAlternativesRandom={orderAlternativesRandom}
                    />
                  )
                })}
                {provided.placeholder}
              </TableBody>
            )}
          </Droppable>
        </DragDropContext>
      </Table>
    </TableContainer>
  )
}

export default AlternativeList
